import React, { useEffect, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { useGlobalContext } from "../Config";
import { Button } from "primereact/button";
import SolicitantesTable from "../components/SolicitantesTable";

const Solicitantes = (props) => {
  const { baseUrl } = useGlobalContext();
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedEmpresas, setSelectedEmpresas] = useState([]);

  const toggleDialog = () => {
    setCurrentStep(1);
    setIsDialogVisible((prev) => !prev);
  };

  // Refactoriza el controlador de cambios para los checkboxes
  const onCheckboxChange = (e) => {
    const selectedValue = e.value;

    setSelectedEmpresas((prevSelectedEmpresas) => {
      const updatedEmpresas = new Set(prevSelectedEmpresas);

      if (updatedEmpresas.has(selectedValue)) {
        updatedEmpresas.delete(selectedValue); // Remueve el valor si ya está seleccionado
      } else {
        updatedEmpresas.add(selectedValue); // Agrega el valor si no está seleccionado
      }

      return [...updatedEmpresas]; // Retorna un array actualizado
    });
  };

  // Componente interno para renderizar las empresas como checkboxes
  const ClienteEmpresas = ({ cliente }) => {
    const empresas = cliente.empresas || []; // Lista de empresas del cliente

    const renderCheckboxes = () => {
      return empresas.map((empresa, index) => (
        <div key={index} className="col-12 md:col-12">
          <div className="field-checkbox">
            <Checkbox
              inputId={`checkOption${index}`} // ID único para cada checkbox
              name="empresa" // Nombre del grupo de checkboxes
              value={empresa} // Valor del checkbox
              checked={selectedEmpresas.includes(empresa)} // Estado del checkbox
              onChange={onCheckboxChange} // Controlador de cambio
            />
            <label htmlFor={`checkOption${index}`}>{empresa}</label>
          </div>
        </div>
      ));
    };

    return <div className="grid">{renderCheckboxes()}</div>;
  };

  return (
    <div className="col-12 md:col-12">
      <h5>Mantenedor Solicitantes</h5>
      <div className="col-12 md:col-12">
        <Button
          label="Agregar Nuevo Solicitante"
          icon="pi pi-plus"
          style={{ marginTop: "3%" }}
          onClick={toggleDialog}
        ></Button>{" "}
      </div>
      <div className="col-12 md:col-12">
        <SolicitantesTable
          isDialogVisible={isDialogVisible}
          setIsDialogVisible={setIsDialogVisible}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          toggleDialog={toggleDialog}
        ></SolicitantesTable>
      </div>
    </div>
  );
};

const comparisonFn = (prevProps, nextProps) => {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.colorMode === nextProps.colorMode &&
    prevProps.isNewThemeLoaded === nextProps.isNewThemeLoaded &&
    prevProps.onNewThemeChange === nextProps.onNewThemeChange
  );
};

export default React.memo(Solicitantes, comparisonFn);
