import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import Loading from "../components/Loading";

const cardStyles = {
  imagen: {
    margin: "auto",
    width: "40%",
  },
};

const Recuperar = ({
  colorMode,
  isNewThemeLoaded,
  onNewThemeChange,
  location,
  handleVolverInicio,
  handleRecuperarContraseña,
  contrasenaRestablecida,
  loading,
  setLoading,
}) => {
  const [emailRecuperar, setEmailRecuperar] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");

  const handleEmailChange = (e) => {
    setEmailRecuperar(e.target.value);
  };
  const handleSubmitRecuperar = (e) => {
    e.preventDefault();
    if (!captchaValue) {
      alert("Por favor complete el CAPTCHA.");
      return;
    }
    setLoading(true);
    handleRecuperarContraseña(emailRecuperar);
  };
  const handleCaptchaChange = (value) => {
    // Called when the user submits a successful reCAPTCHA response
    setCaptchaValue(value);
  };
  return (
    <>
      <>
        {!contrasenaRestablecida ? (
          <>
            <form onSubmit={handleSubmitRecuperar}>
              <div
                className="pages-body login-page flex flex-column"
                style={{ background: "none", backgroundColor: "#20ba84" }}
              >
                <div className="align-self-center mt-auto mb-auto">
                  <div className="pages-panel card flex flex-column">
                    <h1 className="pages-detail">Adquisiciones</h1>
                    <img
                      src="assets/layout/images/Recurso-2.png"
                      alt=""
                      style={cardStyles.imagen}
                    />
                    {loading ? (
                      <Loading />
                    ) : (
                      <>
                        <h5 className="pages-detail mb-6 px-6">
                          Ingrese su correo para recuperar contraseña
                        </h5>

                        <div className="input-panel flex flex-column px-3">
                          <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                              <i className="pi pi-envelope"></i>
                            </span>
                            <span className="p-float-label">
                              <InputText
                                autoComplete="on"
                                type="text"
                                id="inputgroup1"
                                value={emailRecuperar}
                                onChange={handleEmailChange}
                              />
                              <label htmlFor="inputgroup1">
                                Correo Electrónico
                              </label>
                            </span>
                          </div>
                        </div>
                        <div
                          className="p-inputgroup mt-3 mb-6"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {/* Add reCAPTCHA component */}
                          <ReCAPTCHA
                            sitekey="6LeuzLAeAAAAAJxc1lc5L4H_lj-2gFFO81zxbplh"
                            onChange={handleCaptchaChange}
                          />
                        </div>
                        <Button
                          type="submit"
                          name="loginButton" // Add a name to the "Ingresar" button
                          className="login-button mb-3 px-3 mt-1"
                          label="Recuperar Contraseña"
                          style={{ backgroundColor: "#20ba84" }}
                        ></Button>

                        <Button
                          className="login-button mb-3 px-3"
                          label="Volver al inicio"
                          style={{
                            backgroundColor: "white",
                            color: "#6c757d",
                            fontSize: "13px",
                          }}
                          onClick={handleVolverInicio}
                        ></Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </>
        ) : (
          <>
            <form onSubmit={handleSubmitRecuperar}>
              <div
                className="pages-body login-page flex flex-column"
                style={{ background: "none", backgroundColor: "#20ba84" }}
              >
                <div className="align-self-center mt-auto mb-auto">
                  <div className="pages-panel card flex flex-column">
                    <h1 className="pages-detail">Adquisiciones</h1>
                    <img
                      src="assets/layout/images/Recurso-2.png"
                      alt=""
                      style={cardStyles.imagen}
                    />

                    <h6 className="pages-detail mb-6 px-6">
                      Por favor, ingrese con la contraseña que será enviada a su
                      correo
                    </h6>

                    <Button
                      className="login-button mb-3 px-3"
                      label="Volver al inicio"
                      style={{
                        backgroundColor: "white",
                        color: "#6c757d",
                        fontSize: "13px",
                      }}
                      onClick={handleVolverInicio}
                    ></Button>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </>
    </>
  );
};

export default Recuperar;
