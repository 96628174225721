import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { OrganizationChart } from "primereact/organizationchart";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useGlobalContext } from "../Config";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ProgressSpinner } from "primereact/progressspinner";

const Aprobadores = (props) => {
  const { baseUrl, headerText } = useGlobalContext();
  const [clientes, setClientes] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [selectedEmpresa, setSelectedEmpresa] = useState(null);
  const [aprobadores, setAprobadores] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [editedAprobadores, setEditedAprobadores] = useState([]);
  const [dialogCambioAgenteVisible, setDialogCambioAgenteVisible] =
    useState(false);
  const [aprobadorSeleccionado, setAprobadorSeleccionado] = useState(null);
  const [reemplazoSeleccionado, setReemplazoSeleccionado] = useState(null);
  const [detallesAprobador, setDetallesAprobador] = useState(null);
  const [detallesReemplazo, setDetallesReemplazo] = useState(null);
  const [usuarios, setUsuarios] = useState([]);
  const [dialogAgregarAgenteVisible, setDialogAgregarAgenteVisible] =
    useState(false);
  const [nuevoAgenteSeleccionado, setNuevoAgenteSeleccionado] = useState(null);
  const [detallesNuevoAgente, setDetallesNuevoAgente] = useState(null);
  const [loading, setLoading] = useState(false);

  const idUsuario = sessionStorage.getItem("usuario_id");

  const buildURL = (baseURL, params) => {
    const queryString = Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    return `${baseURL}?${queryString}`;
  };

  const getClientes = async () => {
    const endpoint = `${baseUrl}getClientes`;
    try {
      const res = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        headers: headerText,
      });
      const data = await res.json();

      if (data.mensaje !== "OK") {
        throw new Error("Failed to fetch data");
      }

      setClientes(data.clientes);
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  const getEmpresasByIdCliente = async (idCliente) => {
    const endpoint = `${baseUrl}getEmpresasByIdCliente?idCliente=${idCliente}`;
    try {
      const res = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        headers: headerText,
      });
      const data = await res.json();

      if (data.mensaje !== "OK") {
        throw new Error("Failed to fetch data");
      }

      setEmpresas(data.empresas);
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  const getAprobadoresByIdEmpresa = async (idEmpresa) => {
    const endpoint = `${baseUrl}getAprobadoresByIdEmpresa?idEmpresa=${idEmpresa}`;
    try {
      const res = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        headers: headerText,
      });
      const data = await res.json();

      if (data.mensaje !== "OK") {
        throw new Error("Failed to fetch data");
      }

      setAprobadores(data.aprobadores);
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  useEffect(() => {
    getClientes();
  }, []);

  useEffect(() => {
    if (selectedCliente) {
      getEmpresasByIdCliente(selectedCliente.id);
    } else {
      setEmpresas([]);
    }
  }, [selectedCliente]);

  useEffect(() => {
    if (selectedCliente && selectedEmpresa) {
      getAprobadoresByIdEmpresa(selectedEmpresa.id);
    } else {
      setAprobadores([]);
    }
  }, [selectedCliente, selectedEmpresa]);

  const saveChanges = async () => {
    if (!selectedEmpresa) return;

    if (
      !window.confirm("¿Está seguro que desea guardar los cambios de montos?")
    ) {
      return;
    }

    setLoading(true);

    const sortedAprobadores = [...editedAprobadores].sort(
      (a, b) => b.montoTotal - a.montoTotal
    );

    let nivelesCambiaron = false;
    const cambiosDeNivel = [];

    sortedAprobadores.forEach((aprobador, index) => {
      const nuevoNivel = index + 1;
      if (aprobador.nivel !== nuevoNivel) {
        nivelesCambiaron = true;
        cambiosDeNivel.push({
          idUsuario: aprobador.usuario.id,
          nivelAntiguo: aprobador.nivel,
          nivelNuevo: nuevoNivel,
        });
        aprobador.nivel = nuevoNivel;
      }
    });

    // Desactivar aprobadores existentes
    await fetch(
      `${baseUrl}desactivarAprobadores?idEmpresa=${encodeURIComponent(
        selectedEmpresa.id
      )}`,
      {
        method: "POST",
        credentials: "include",
        headers: headerText,
        body: JSON.stringify({ idEmpresa: selectedEmpresa.id }),
      }
    );

    // Actualizar aprobadores con nuevos montos y niveles
    const promises = sortedAprobadores.map((aprobador) => {
      const params = {
        idEmpresa: selectedEmpresa.id,
        idUsuario: aprobador.usuario.id,
        nombre: aprobador.nombre,
        correo: aprobador.correo,
        nivel: aprobador.nivel,
        montoTotal: aprobador.montoTotal,
        idUsuarioModificador: idUsuario,
      };
      const url = buildURL(`${baseUrl}updateCadenaAprobacion`, params);
      return fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          ...headerText,
          "Content-Type": "application/json",
        },
      });
    });

    await Promise.all(promises);

    // Llamar a la API para actualizar las solicitudes si hubo cambios en los niveles
    if (nivelesCambiaron) {
      const solicitudesPromises = cambiosDeNivel.map(
        async (cambio, index, array) => {
          if (index < array.length - 1) {
            const actualizarSolicitudesUrl = `${baseUrl}actualizarSolicitudesCambioMonto`;
            return fetch(
              buildURL(actualizarSolicitudesUrl, {
                id_empresa: selectedEmpresa.id,
                id_usuario_uno: array[index + 1].idUsuario,
                id_usuario_dos: array[index].idUsuario,
              }),
              {
                method: "POST",
                credentials: "include",
                headers: {
                  ...headerText,
                  "Content-Type": "application/json",
                },
              }
            );
          }
        }
      );

      await Promise.all(solicitudesPromises);
    }

    await getAprobadoresByIdEmpresa(selectedEmpresa.id); // Actualiza la lista de aprobadores
    closeDialog();
    setLoading(false);
  };

  const verificarMontos = () => {
    const montos = editedAprobadores.map((a) => parseInt(a.montoTotal, 10));
    const montosUnicos = new Set(montos);
    if (montos.length !== montosUnicos.size) {
      alert("Hay montos iguales entre los aprobadores. Por favor, verifique.");
      return false;
    }
    return true;
  };

  const handleGuardarClick = () => {
    if (verificarMontos()) {
      saveChanges();
    }
  };

  const saveCambiarAgente = async () => {
    if (!selectedEmpresa || !aprobadorSeleccionado || !reemplazoSeleccionado)
      return;

    setLoading(true);
    await fetch(
      `${baseUrl}desactivarAprobadores?idEmpresa=${encodeURIComponent(
        selectedEmpresa.id
      )}`,
      {
        method: "POST",
        credentials: "include",
        headers: headerText,
        body: JSON.stringify({ idEmpresa: selectedEmpresa.id }),
      }
    );

    const aprobadoresActivos = aprobadores.filter(
      (a) => a.activo && a.nombre !== aprobadorSeleccionado.nombre
    );

    const nuevoAprobador = {
      ...reemplazoSeleccionado,
      idUsuario: reemplazoSeleccionado.usuario?.id || reemplazoSeleccionado.id, // Ajuste aquí
      correo:
        typeof reemplazoSeleccionado.usuario === "string"
          ? reemplazoSeleccionado.usuario
          : reemplazoSeleccionado.correo,
      montoTotal: aprobadorSeleccionado.montoTotal,
      nivel: aprobadorSeleccionado.nivel,
    };

    aprobadoresActivos.push(nuevoAprobador);

    const sortedAprobadores = aprobadoresActivos.sort(
      (a, b) => b.montoTotal - a.montoTotal
    );

    sortedAprobadores.forEach((aprobador, index) => {
      aprobador.nivel = index + 1;
    });

    const cambiarPerfilUsuario = async (idUsuario, idPerfil) => {
      const url = `${baseUrl}cambiarPerfilUsuario?idUsuario=${encodeURIComponent(
        idUsuario
      )}&idPerfil=${encodeURIComponent(idPerfil)}`;
      return fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          ...headerText,
          "Content-Type": "application/json",
        },
      });
    };

    const desactivarUsuarioPerfil = async (idUsuario) => {
      const url = `${baseUrl}desactivarUsuarioPerfil?idUsuario=${encodeURIComponent(
        idUsuario
      )}`;
      return fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          ...headerText,
          "Content-Type": "application/json",
        },
      });
    };

    const updateUsuarioPerfil = async (idUsuario, idPerfil, idModificador) => {
      const url = `${baseUrl}updateUsuarioPerfil?idUsuario=${encodeURIComponent(
        idUsuario
      )}&idPerfil=${encodeURIComponent(
        idPerfil
      )}&idModificador=${encodeURIComponent(idModificador)}`;
      return fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          ...headerText,
          "Content-Type": "application/json",
        },
      });
    };

    if (nuevoAprobador.idUsuario) {
      await cambiarPerfilUsuario(nuevoAprobador.idUsuario, 4);
      await desactivarUsuarioPerfil(nuevoAprobador.idUsuario);
      await updateUsuarioPerfil(nuevoAprobador.idUsuario, 4, idUsuario);
    }

    const promises = sortedAprobadores.map((aprobador) => {
      const params = {
        idEmpresa: selectedEmpresa.id,
        nombre: aprobador.nombre,
        correo: aprobador.correo,
        nivel: aprobador.nivel,
        montoTotal: aprobador.montoTotal,
        idUsuarioModificador: idUsuario,
        idUsuario: aprobador.usuario?.id || aprobador.idUsuario, // Ajuste aquí
      };

      const url = buildURL(`${baseUrl}updateCadenaAprobacion`, params);
      return fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          ...headerText,
          "Content-Type": "application/json",
        },
      });
    });

    await Promise.all(promises);

    const actualizarSolicitudesUrl = `${baseUrl}actualizarSolicitudesAprobacion`;
    await fetch(
      buildURL(actualizarSolicitudesUrl, {
        id_empresa: selectedEmpresa.id,
        id_usuario_antiguo: aprobadorSeleccionado.usuario.id,
        id_usuario_nuevo:
          reemplazoSeleccionado.usuario?.id || reemplazoSeleccionado.id,
      }),
      {
        method: "POST",
        credentials: "include",
        headers: {
          ...headerText,
          "Content-Type": "application/json",
        },
      }
    );

    // Check and update profile of the replaced user if needed
    const ultimoPerfilResponse = await fetch(
      `${baseUrl}ultimoPerfilByIdUsuario?idUsuario=${aprobadorSeleccionado.usuario.id}`,
      {
        method: "GET",
        credentials: "include",
        headers: headerText,
      }
    );

    const ultimoPerfilData = await ultimoPerfilResponse.json();
    if (ultimoPerfilData.respuesta && ultimoPerfilData.data.length > 0) {
      const ultimoPerfil = ultimoPerfilData.data[0].perfil.id;
      if (ultimoPerfil !== 4) {
        await cambiarPerfilUsuario(
          aprobadorSeleccionado.usuario.id,
          ultimoPerfil
        );
        await desactivarUsuarioPerfil(aprobadorSeleccionado.usuario.id);
        await updateUsuarioPerfil(
          aprobadorSeleccionado.usuario.id,
          ultimoPerfil,
          idUsuario
        );
      }
    }

    await getAprobadoresByIdEmpresa(selectedEmpresa.id); // Actualiza la lista de aprobadores
    closeCambioAgenteDialog();
    setReemplazoSeleccionado(null);
    setDetallesReemplazo(null);
    setLoading(false);
  };

  const getUsuarios = async () => {
    const endpoint = `${baseUrl}getUsuarios`;
    try {
      const res = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        headers: headerText,
      });
      const data = await res.json();

      if (data.mensaje !== "OK") {
        throw new Error("Failed to fetch data");
      }

      setUsuarios(data.usuarios);
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  const transformData = (aprobadores) => {
    const aprobadoresActivos = aprobadores.filter(
      (aprobador) => aprobador.activo === true
    );

    if (!aprobadoresActivos.length) {
      return [
        {
          label: "Femoglas",
          expanded: true,
          data: { nombre: "Femoglas", nivel: "", montoTotal: "" },
          children: [],
        },
      ];
    }

    const levels = {};
    aprobadoresActivos.forEach((aprobador) => {
      if (!levels[aprobador.nivel]) {
        levels[aprobador.nivel] = [];
      }
      levels[aprobador.nivel].push({
        label: aprobador.nombre || "Sin Nombre",
        expanded: true,
        data: {
          nombre: aprobador.nombre || "Sin Nombre",
          correo: aprobador.correo || "Sin Correo",
          empresa: aprobador.empresa ? aprobador.empresa.nombre : "Sin Empresa",
          montoTotal: aprobador.montoTotal || 0,
        },
        children: [],
      });
    });

    const buildTree = (nivel) => {
      if (!levels[nivel]) return [];
      const nodes = levels[nivel];
      nodes.forEach((node) => {
        node.children = buildTree(nivel + 1);
      });
      return nodes;
    };

    return buildTree(1);
  };

  const nodeTemplate = (node) => {
    if (!node || !node.data) {
      return null;
    }

    return (
      <div>
        <div>
          <strong>{node.data.nombre}</strong>
        </div>
        <div>Monto Desde: $ {node.data.montoTotal}</div>
      </div>
    );
  };

  const openDialog = () => {
    const aprobadoresActivos = aprobadores.filter(
      (aprobador) => aprobador.activo === true
    );
    setEditedAprobadores(aprobadoresActivos);
    setDialogVisible(true);
  };

  const closeDialog = () => {
    setDialogVisible(false);
  };

  const handleMontoChange = (e, aprobador) => {
    const updatedAprobadores = editedAprobadores.map((a) =>
      a.id === aprobador.id ? { ...a, montoTotal: e.target.value } : a
    );
    setEditedAprobadores(updatedAprobadores);
  };

  const closeCambioAgenteDialog = () => {
    setDialogCambioAgenteVisible(false);
    setAprobadorSeleccionado(null);
    setReemplazoSeleccionado(null);
    setDetallesAprobador(null);
    setDetallesReemplazo(null);
  };

  const getFilteredAprobadores = () => {
    const aprobadoresActivosNombres = new Set(
      aprobadores.filter((a) => a.activo).map((a) => a.nombre)
    );

    const aprobadoresFiltrados = aprobadores.filter(
      (aprobador) =>
        aprobador.nombre !== aprobadorSeleccionado?.nombre &&
        !aprobador.activo &&
        !aprobadoresActivosNombres.has(aprobador.nombre)
    );

    const uniqueNames = new Set();
    return aprobadoresFiltrados.filter((aprobador) => {
      if (uniqueNames.has(aprobador.nombre)) {
        return false;
      }
      uniqueNames.add(aprobador.nombre);
      return true;
    });
  };

  const getUsuariosFiltrados = () => {
    const aprobadoresActivosNombres = new Set(
      aprobadores.filter((a) => a.activo).map((a) => a.nombre)
    );

    const aprobadoresDesactivados = aprobadores
      .filter((a) => !a.activo && !aprobadoresActivosNombres.has(a.nombre))
      .map((a) => ({
        label: a.nombre,
        value: {
          ...a,
          esAprobador: true, // Indicador para distinguir los objetos aprobador
        },
      }));

    const usuariosNoAprobadores = usuarios
      .filter((usuario) => !aprobadoresActivosNombres.has(usuario.nombre))
      .map((usuario) => ({
        label: usuario.nombre,
        value: {
          ...usuario,
          esAprobador: false, // Indicador para distinguir los objetos usuario
        },
      }));

    const usuariosYDesactivados = [
      ...aprobadoresDesactivados,
      ...usuariosNoAprobadores,
    ];

    const uniqueNames = new Map();
    usuariosYDesactivados.forEach((item) => {
      if (!uniqueNames.has(item.label)) {
        uniqueNames.set(item.label, item);
      } else if (item.value.esAprobador) {
        // Prioriza el aprobador si ya existe un usuario con el mismo nombre
        uniqueNames.set(item.label, item);
      }
    });

    return Array.from(uniqueNames.values());
  };

  return (
    <div className="col-12 md:col-12">
      <h5>Mantenedor Aprobadores</h5>
      <div className="card grid">
        <div className="col-4 md:col-4">
          <label htmlFor="clientesDropdown">Seleccione un Grupo:</label>
          <Dropdown
            id="clientesDropdown"
            value={selectedCliente}
            options={clientes}
            onChange={(e) => {
              setSelectedCliente(e.value);
              setSelectedEmpresa(null);
            }}
            optionLabel="nombre"
            placeholder="Seleccione una opción"
            style={{ width: "100%" }}
          />
        </div>
        <div className="col-4 md:col-4">
          <label htmlFor="empresasDropdown">Seleccione una Empresa:</label>
          <Dropdown
            id="empresasDropdown"
            value={selectedEmpresa}
            options={empresas}
            onChange={(e) => setSelectedEmpresa(e.value)}
            optionLabel="nombre"
            placeholder="Seleccione una opción"
            style={{ width: "100%" }}
            disabled={!selectedCliente}
          />
        </div>
      </div>
      <div className="col-12 md:col-12">
        <h5>
          Cadena de Aprobación Actual para{" "}
          {selectedEmpresa ? selectedEmpresa.nombre : ""}
        </h5>
        <OrganizationChart
          value={transformData(aprobadores) || []}
          nodeTemplate={nodeTemplate}
          selectionMode
        />
      </div>
      {selectedEmpresa && (
        <div
          className="button-group"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <Button
            label="Cambiar Monto"
            onClick={openDialog}
            style={{ width: "40vw" }}
          />

          <Button
            label="Cambiar Agente"
            onClick={() => {
              getUsuarios(); // Obtener los usuarios antes de mostrar el diálogo
              setDialogCambioAgenteVisible(true);
            }}
            style={{ width: "40vw" }}
          />
        </div>
      )}
      <Dialog
        header="Editar Montos de Aprobadores"
        visible={dialogVisible}
        style={{ width: "50vw" }}
        modal
        onHide={closeDialog}
      >
        <DataTable
          value={editedAprobadores}
          emptyMessage="No data found."
          className="datatable-responsive"
        >
          <Column field="nombre" header="Nombre" />
          <Column field="correo" header="Correo" />
          <Column field="nivel" header="Nivel" />
          <Column
            field="montoTotal"
            header="Monto Desde"
            body={(rowData) => (
              <InputText
                value={rowData.montoTotal}
                onChange={(e) => handleMontoChange(e, rowData)}
              />
            )}
          />
        </DataTable>
        <div className="p-d-flex p-jc-end">
          <Button
            label="Guardar"
            icon="pi pi-check"
            onClick={handleGuardarClick}
            disabled={loading}
          />
          <Button
            label="Cancelar"
            icon="pi pi-times"
            onClick={closeDialog}
            className="p-button-secondary"
          />
        </div>
      </Dialog>
      <Dialog
        header="Cambiar Agente"
        visible={dialogCambioAgenteVisible}
        style={{ width: "50vw" }}
        modal
        onHide={closeCambioAgenteDialog}
      >
        <div className="grid">
          <div className="col-6 md:col-6">
            <label htmlFor="titulo">Aprobador a reemplazar:</label>
            <Dropdown
              header="Seleccione el aprobador que desea reemplazar"
              value={aprobadorSeleccionado}
              options={aprobadores.filter((ap) => ap.activo)}
              onChange={(e) => {
                setAprobadorSeleccionado(e.value);
                setDetallesAprobador(e.value);
                setReemplazoSeleccionado(null);
                setDetallesReemplazo(null);
              }}
              filter
              optionLabel="nombre"
              placeholder="Seleccione un reemplazante"
              style={{ width: "100%" }}
            />
            {detallesAprobador && (
              <div className="card" style={{ marginTop: "5px" }}>
                <h5>Información del Aprobador actual:</h5>
                <p>
                  <strong>Nombre:</strong> {detallesAprobador.nombre}
                </p>
                <p>
                  <strong>Correo:</strong> {detallesAprobador.correo}
                </p>
                <p>
                  <strong>Monto Total:</strong> $ {detallesAprobador.montoTotal}
                </p>
              </div>
            )}
          </div>
          <div className="col-6 md:col-6">
            {aprobadorSeleccionado && (
              <>
                <label htmlFor="titulo">Aprobador reemplazante:</label>
                <Dropdown
                  value={reemplazoSeleccionado}
                  options={getUsuariosFiltrados()}
                  onChange={(e) => {
                    setReemplazoSeleccionado(e.value);
                    setDetallesReemplazo(e.value);
                  }}
                  optionLabel="label"
                  filter
                  placeholder="Seleccione un nuevo aprobador"
                  emptyMessage="No hay aprobadores disponibles, agregue un nuevo aprobador"
                  style={{ width: "100%" }}
                />
              </>
            )}
            {detallesReemplazo && (
              <div className="card" style={{ marginTop: "5px" }}>
                <h5>Información del Reemplazante:</h5>
                <p>
                  <strong>Nombre:</strong> {detallesReemplazo.nombre}
                </p>
                <p>
                  <strong>Correo:</strong>{" "}
                  {detallesReemplazo.esAprobador
                    ? detallesReemplazo.correo
                    : detallesReemplazo.usuario}
                </p>
                <p>
                  <strong>Monto Total:</strong> $ {detallesAprobador.montoTotal}
                </p>
              </div>
            )}
          </div>
          <div className="col-12 md:col-12 p-d-flex p-jc-end">
            <Button
              label="Guardar Cambio"
              onClick={saveCambiarAgente}
              disabled={loading}
            />
          </div>
          {loading && (
            <div className="p-d-flex p-jc-center" style={{ marginTop: "20px" }}>
              <ProgressSpinner />
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
};

const comparisonFn = (prevProps, nextProps) => {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.colorMode === nextProps.colorMode &&
    prevProps.isNewThemeLoaded === nextProps.isNewThemeLoaded &&
    prevProps.onNewThemeChange === nextProps.onNewThemeChange
  );
};

export default React.memo(Aprobadores, comparisonFn);
