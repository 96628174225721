import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import { DataTable } from "primereact/datatable";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { useGlobalContext } from "../Config";
import { classNames } from "primereact/utils";

import { useLocation } from "react-router-dom";

import "../assets/ultimate.css";

// import TablaSolicitudes from './TablaSolicitudes';

const Solicitudes = (props) => {
  const [empresas, setEmpresas] = useState(null);
  const [empresa, setEmpresa] = useState(null);
  const [ingresada, setIngresada] = useState(0);
  const [revision, setRevision] = useState(0);
  const [aprobacion, setAprobacion] = useState(0);
  const [compra, setCompra] = useState(0);
  const [finalizada, setFinalizada] = useState(0);
  const [rechazada, setRechazada] = useState(0);
  const [eliminada, setEliminada] = useState(0);
  const { baseUrl } = useGlobalContext();
  const { headerText } = useGlobalContext();
  const [solicitudes, setSolicitudes] = useState([]);
  const [loading, setLoading] = useState(true);

  const [visible, setVisible] = useState(false);

  const [tipoSeleccionado, setTipoSeleccionado] = useState("Nacional");
  const [modoSeleccionado, setModoSeleccionado] = useState("Directa");
  const [monedaSeleccionado, setMonedaSeleccionado] = useState(null);
  const [motivosCompra, setMotivosCompra] = useState(null);
  const [motivoSeleccionado, setMotivoSeleccionado] = useState(null);
  const [solicitantes, setSolicitantes] = useState(null);
  const [solicitanteSeleccionado, setSolicitanteSeleccionado] = useState(null);

  const [comentario, setComentario] = useState("");

  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const id = params.get("id") || "Default value for param1";

  // Get today's date
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Months are zero-based
  var day = ("0" + currentDate.getDate()).slice(-2);

  var formattedDate = year + "-" + month + "-" + day;

  // Calculate one month ago
  var oneMonthAgo = new Date();
  oneMonthAgo.setMonth(currentDate.getMonth() - 1);
  var yearAgo = oneMonthAgo.getFullYear();
  var monthAgo = ("0" + (oneMonthAgo.getMonth() + 1)).slice(-2);
  var dayAgo = ("0" + oneMonthAgo.getDate()).slice(-2);

  var formattedDateAgo = yearAgo + "-" + monthAgo + "-" + dayAgo;

  const [fechaIngreso, setFechaIngreso] = useState(formattedDate);
  const [fechaDeseada, setFechaDeseada] = useState(formattedDate);
  const [fechaDesdeSolicitudes, setFechaDesdeSolicitudes] =
    useState(formattedDateAgo);
  const [fechaHastaSolicitudes, setFechaHastaSolicitudes] =
    useState(formattedDate);

  useEffect(() => {
    // Check if there is a selected empresa in localStorage
    const storedEmpresa = sessionStorage.getItem("selectedEmpresa");
    if (storedEmpresa !== undefined && storedEmpresa !== "") {
      setEmpresa(JSON.parse(storedEmpresa));
    }
  }, []);

  const tipoCompra = [
    { label: "Nacional", value: "Nacional" },
    { label: "Internacional", value: "Internacional" },
  ];

  const modoCompra = [
    { label: "Directa", value: "Directa" },
    { label: "Licitada", value: "Licitada" },
  ];

  const monedaInternacional = [
    { label: "Dolar Americano", value: "USDA", icon: "pi pi-dollar" },
    { label: "Euro", value: "EUROA", icon: "pi pi-euro" },
  ];

  const monedaNacional = [
    { label: "Pesos Chilenos", value: "CLP", icon: "pi pi-money-bill" },
    { label: "Dolar Americano", value: "USD", icon: "pi pi-dollar" },
    { label: "Unidad de Fomento", value: "UF", icon: "pi pi-chart-line" },
  ];

  const showDialog = async (empresa) => {
    await getMotivos(empresa); // Wait for getMotivos to complete
    setVisible(true);
  };

  const hideDialog = () => {
    setVisible(false);
  };

  const getMotivos = async (empresa) => {
    const endpoint = baseUrl + "getMotivos?id_empresa=" + empresa;
    try {
      const response = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: new Headers(),
      });

      if (!response.ok) {
        throw new Error("Failed to create the request");
      }

      const data = await response.json();
      setMotivoSeleccionado(data.motivosCompra[0]);
      setMotivosCompra(data.motivosCompra);
    } catch (error) {
      console.error("Error:", error.message);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const navigate = useNavigate();

  const submitForm = async () => {
    const fechaIngresoValida = handleFechaIngresoChange();
    const fechaDeseadaValida = handleFechaDeseadaChange();

    if (!fechaIngresoValida && !fechaDeseadaValida) {
      alert("Las fechas de ingreso y deseada no pueden ser menor a hoy");
      return;
    }

    if (!fechaIngresoValida) {
      alert("La fecha de ingreso no puede ser menor a hoy");
      return;
    }

    if (!fechaDeseadaValida) {
      alert("La fecha deseada no puede ser menor a hoy");
      return;
    }

    if (monedaSeleccionado === null) {
      alert("Debe seleccionar una moneda");
      return;
    }

    if (
      fechaIngresoValida &&
      fechaDeseadaValida &&
      monedaSeleccionado != null
    ) {
      const params = new URLSearchParams({
        usuario: props.usuario_id,
        solicitante: solicitanteSeleccionado.id,
        empresa: empresa.id,
        tipoCompra: tipoSeleccionado,
        modoCompra: modoSeleccionado,
        fechaIngreso: convertDateFormat(fechaIngreso),
        fechaDeseada: convertDateFormat(fechaDeseada),
        motivo: motivoSeleccionado.descripcion,
        comentario: comentario,
        moneda: monedaSeleccionado,
      });

      const endpoint = `${baseUrl}crearSolicitud?${params.toString()}`;

      try {
        const data = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: headerText,
        }).then((res) => res.json());

        console.log(data);

        if (data.mensaje !== "OK") {
          throw new Error("Failed to create the request");
        }

        console.log(data.solicitud);
        processData(data.solicitud);
      } catch (error) {
        console.error("Error:", error.message);
        // Handle the error (e.g., show an error message to the user)
      }
    }
  };

  const processData = (id) => {
    console.log(id);
    hideDialog(); // Close the modal after submitting the form
    navigate("/solicitud?id=" + id);
  };

  const filtro = (usuario, empresa, filtro) => {
    setLoading(true);
    const endpoint = `${baseUrl}getListaSolicitudes?id_empresa=${empresa}&id_usuario=${usuario}&inicio=${fechaDesdeSolicitudes}&termino=${fechaHastaSolicitudes}&estado=${filtro}`;

    (async () => {
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: new Headers(),
      }).then((res) => res.json());
      setSolicitudes(data.solicitudes);
      setLoading(false);
    })();
  };
  const convertDateFormat = (inputDate) => {
    let parts = inputDate.split("-");
    if (parts.length === 3) {
      let year = parts[0];
      let month = parts[1];
      let day = parts[2];

      // Construct the new date string in "dd/mm/yyyy" format
      let formattedDate = day + "/" + month + "/" + year;
      return formattedDate;
    } else {
      // Handle invalid input date format
      console.error('Invalid date format. Expected "yyyy-mm-dd".');
      return null; // or throw an error, depending on your use case
    }
  };
  const itemTemplate = (option) => {
    return (
      <div className="p-d-flex p-ai-center">
        <i
          className={classNames("pi", option.icon)}
          style={{ marginRight: "0.5rem" }}
        ></i>
        {option.label}
      </div>
    );
  };
  const handleFechaDeseadaChange = () => {
    const selectedFechaDeseada = fechaDeseada;
    const today = new Date().toISOString().split("T")[0];

    if (selectedFechaDeseada < today) {
      return false;
    }

    if (fechaIngreso && selectedFechaDeseada < fechaIngreso) {
      return false;
    }

    setFechaDeseada(selectedFechaDeseada);
    return true;
  };

  const handleFechaIngresoChange = () => {
    const selectedFechaIngreso = fechaIngreso;
    const today = new Date().toISOString().split("T")[0];

    if (selectedFechaIngreso < today) {
      return false;
    }

    setFechaIngreso(selectedFechaIngreso);
    return true;
  };

  const handleSearchSolicitudes = async () => {
    // Validación de empresa
    if (!empresa) {
      alert("Por favor seleccione una empresa.");
      return;
    }

    // Validación de fechas
    if (!fechaDesdeSolicitudes || !fechaHastaSolicitudes) {
      alert("Por favor seleccione fecha de inicio y término.");
      return;
    }

    // Validación de formato de fecha
    const fechaDesde = new Date(fechaDesdeSolicitudes);
    const fechaHasta = new Date(fechaHastaSolicitudes);
    if (isNaN(fechaDesde.getTime()) || isNaN(fechaHasta.getTime())) {
      alert("El formato de fecha es inválido.");
      return;
    }

    // Validación de rango de fechas
    if (fechaDesde > fechaHasta) {
      alert("La fecha de inicio no puede ser después de la de término.");
      return;
    }
    const endpoint = `${baseUrl}getListaSolicitudes?id_empresa=${empresa.id}&id_usuario=${props.usuario_id}&inicio=${fechaDesdeSolicitudes}&termino=${fechaHastaSolicitudes}&estado=`;
    const endpointContador = `${baseUrl}getContadores?id_empresa=${empresa.id}&id_usuario=${props.usuario_id}&inicio=${fechaDesdeSolicitudes}&termino=${fechaHastaSolicitudes}`;

    const fetchData = async () => {
      try {
        setLoading(true);
        const [contadoresResponse, solicitudesResponse] = await Promise.all([
          fetch(endpointContador, {
            method: "GET",
            credentials: "include",
            redirect: "follow",
            headers: new Headers(),
          }).then((res) => res.json()),
          fetch(endpoint, {
            method: "GET",
            credentials: "include",
            redirect: "follow",
            headers: new Headers(),
          }).then((res) => res.json()),
        ]);
        const contadores = contadoresResponse.contadores;
        const solicitudes = solicitudesResponse.solicitudes;
        setSolicitudes(solicitudes);
        setIngresada(contadores[0].cantidad);
        setRevision(contadores[1].cantidad);
        setAprobacion(contadores[2].cantidad);
        setCompra(contadores[3].cantidad);
        setRechazada(contadores[4].cantidad);
        setFinalizada(contadores[5].cantidad);
        setEliminada(contadores[6].cantidad);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  };

  return (
    <div className="grid dashboard">
      <div className="col-12 md:col-12">
        <div>
          <div>
            <h5>Seleccione filtros para solicitudes</h5>

            <div className="formgroup-inline">
              <div className="field">
                <label htmlFor="selectEmpresa">Empresa</label>
                <DropdownPageEmpresa
                  usuario_id={props.usuario_id}
                  empresa={empresa}
                  empresas={empresas}
                  setEmpresa={setEmpresa}
                  setEmpresas={setEmpresas}
                  setSolicitanteSeleccionado={setSolicitanteSeleccionado}
                  setSolicitantes={setSolicitantes}
                  baseUrl={baseUrl}
                  id="selectEmpresa"
                  loading={loading}
                  fechaDesdeSolicitudes={fechaDesdeSolicitudes}
                  fechaHastaSolicitudes={fechaHastaSolicitudes}
                />
              </div>
              <div className="field">
                <label htmlFor="firstname1">Fecha Desde</label>
                <InputText
                  id="firstname1"
                  type="date"
                  value={fechaDesdeSolicitudes}
                  onChange={(e) => {
                    setFechaDesdeSolicitudes(e.target.value);
                  }}
                  min="1000-01-01"
                  max="9999-12-31"
                  disabled={loading}

                  // Props for the first date picker
                />{" "}
              </div>
              <div className="field">
                <label htmlFor="lastname1">Fecha Hasta</label>
                <InputText
                  type="date"
                  value={fechaHastaSolicitudes}
                  onChange={(e) => {
                    setFechaHastaSolicitudes(e.target.value);
                  }}
                  min="1000-01-01"
                  max="9999-12-31"
                  disabled={loading}

                  // Props for the second date picker
                />{" "}
              </div>
              <Button
                label="Buscar"
                icon="pi pi-search"
                onClick={handleSearchSolicitudes}
                disabled={loading}
              ></Button>{" "}
            </div>
          </div>
          <h5>Resumen</h5>
          <div className="grid dashboard mb-4">
            <div className=" flex flex-wrap justify-content-center gap-3 mt-4 col-12 md:col-12">
              <Button
                type="button"
                icon="pi pi-plus fs-large"
                className="p-button mr-2 mb-2"
                style={{
                  backgroundColor: "#B3E5FC",
                  color: "#23547B",
                  borderColor: "#23547B",
                  fontColor: "#23547B ",
                }}
                onClick={() =>
                  filtro(props.usuario_id, empresa.id, "Ingresada")
                }
                disabled={loading}
              >
                <span
                  style={{ fontSize: "1.2rem" }}
                  className="mr-2 ml-2"
                >{`Ingresada: ${ingresada}`}</span>
              </Button>{" "}
              <Button
                type="button"
                icon="pi pi-check #ECCFFF fs-large"
                className="p-button mr-2 mb-2"
                style={{
                  backgroundColor: "#ECCFFF",
                  color: "#694382",
                  borderColor: "black ",
                  fontColor: "#694382",
                }}
                onClick={() => filtro(props.usuario_id, empresa.id, "Revision")}
                disabled={loading}
              >
                <span
                  style={{ fontSize: "1.2rem" }}
                  className="mr-2 ml-2"
                >{`Revisión: ${revision}`}</span>
              </Button>
              <Button
                type="button"
                icon="pi pi-check #ECCFFF fs-large"
                className="p-button mr-2 mb-2"
                style={{
                  backgroundColor: "#ECCFFF",
                  color: "#694382",
                  borderColor: "black ",
                  fontColor: "#694382",
                }}
                onClick={() =>
                  filtro(props.usuario_id, empresa.id, "Aprobacion")
                }
                disabled={loading}
              >
                <span
                  style={{ fontSize: "1.2rem" }}
                  className="mr-2 ml-2"
                >{`Aprobación: ${aprobacion}`}</span>
              </Button>
              <Button
                type="button"
                icon="pi pi-dollar fs-large"
                className="p-button mr-2 mb-2"
                style={{
                  backgroundColor: "#C8E6C9",
                  color: "#256029",
                  borderColor: "#256029 ",
                  fontColor: "#256029",
                }}
                onClick={() => filtro(props.usuario_id, empresa.id, "Compra")}
                disabled={loading}
              >
                <span
                  style={{ fontSize: "1.2rem" }}
                >{`Compra: ${compra}`}</span>
              </Button>
              <Button
                type="button"
                icon="pi pi-check-square  fs-large"
                className="p-button mr-2 mb-2"
                style={{
                  backgroundColor: "#FFD8B2",
                  color: "#694382",
                  borderColor: "#805B36 ",
                  fontColor: "#805B36",
                }}
                onClick={() =>
                  filtro(props.usuario_id, empresa.id, "Finalizada")
                }
                disabled={loading}
              >
                <span
                  style={{ fontSize: "1.2rem" }}
                  className="mr-2 ml-2"
                >{`Finalizada: ${finalizada}`}</span>
              </Button>
              <Button
                type="button"
                icon="pi pi-times  fs-large"
                className="p-button mr-2 mb-2"
                style={{
                  backgroundColor: "#FFCDD2",
                  color: "#694382",
                  borderColor: "#C63737 ",
                  fontColor: "#C63737",
                }}
                onClick={() =>
                  filtro(props.usuario_id, empresa.id, "Rechazada")
                }
                disabled={loading}
              >
                <span
                  style={{ fontSize: "1.2rem" }}
                  className="mr-2 ml-2"
                >{`Rechazada: ${rechazada}`}</span>
              </Button>
              <Button
                type="button"
                icon="pi pi-trash  fs-large"
                className="p-button mr-2 mb-2"
                style={{
                  backgroundColor: "#FFCDD2",
                  color: "#694382",
                  borderColor: "#C63737 ",
                  fontColor: "#C63737",
                }}
                onClick={() =>
                  filtro(props.usuario_id, empresa.id, "Eliminada")
                }
                disabled={loading}
              >
                <span
                  style={{ fontSize: "1.2rem" }}
                  className="mr-2 ml-2"
                >{`Eliminada: ${eliminada}`}</span>
              </Button>
            </div>
          </div>
        </div>
        <div
          className="grid dashboard"
          style={{ paddingLeft: "1%", paddingBottom: "1%" }}
        >
          <Button
            label="Crear Solicitud"
            icon="pi pi-plus"
            onClick={() => showDialog(empresa.id)}
            disabled={loading}
            className="p-button-success"
          />
        </div>
        {empresa !== null && (
          <div>
            <h5>Solicitudes de {empresa.empresa}</h5>
            <TablaSolicitudes
              usuario_id={props.usuario_id}
              empresa={empresa}
              setIngresada={setIngresada}
              setRevision={setRevision}
              setAprobacion={setAprobacion}
              setCompra={setCompra}
              setFinalizada={setFinalizada}
              setRechazada={setRechazada}
              setSolicitudes={setSolicitudes}
              solicitudes={solicitudes}
              baseUrl={baseUrl}
              setEliminada={setEliminada}
              loading={loading}
              setLoading={setLoading}
              fechaDesdeSolicitudes={fechaDesdeSolicitudes}
              fechaHastaSolicitudes={fechaHastaSolicitudes}
            />
          </div>
        )}
      </div>
      <Dialog
        header="Crear solicitud de compra"
        visible={visible}
        onHide={() => {
          setVisible(false);
          setMonedaSeleccionado(null);
        }}
        modal
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
      >
        <div className="card p-fluid">
          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="tipo" style={{ fontWeight: "bold" }}>
                Tipo de Compra
              </label>
              <Dropdown
                id="tipo"
                options={tipoCompra}
                value={tipoSeleccionado}
                onChange={(e) => setTipoSeleccionado(e.value)}
                placeholder="Seleccione una opción"
              />
            </div>
            <div className="field col">
              <label htmlFor="modo" style={{ fontWeight: "bold" }}>
                Modo de Compra
              </label>
              <Dropdown
                id="modo"
                options={modoCompra}
                value={modoSeleccionado}
                onChange={(e) => setModoSeleccionado(e.value)}
                placeholder="Seleccione una opción"
              />
            </div>
          </div>
          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="moneda" style={{ fontWeight: "bold" }}>
                Moneda
              </label>
              {tipoSeleccionado === "Nacional" && (
                <Dropdown
                  id="moneda"
                  options={monedaNacional}
                  value={monedaSeleccionado}
                  onChange={(e) => setMonedaSeleccionado(e.value)}
                  optionLabel="label"
                  placeholder="Seleccione moneda"
                  itemTemplate={itemTemplate}
                />
              )}
              {tipoSeleccionado === "Internacional" && (
                <Dropdown
                  id="moneda"
                  options={monedaInternacional}
                  value={monedaSeleccionado}
                  onChange={(e) => setMonedaSeleccionado(e.value)}
                  placeholder="Seleccione moneda"
                  itemTemplate={itemTemplate}
                />
              )}
            </div>
            <div className="field col"></div>
          </div>
          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="tipo" style={{ fontWeight: "bold" }}>
                Fecha de Ingreso
              </label>
              <InputText
                type="date"
                value={fechaIngreso}
                onChange={(e) => setFechaIngreso(e.target.value)}
                min="1000-01-01"
                max="9999-12-31"
              />
            </div>
            <div className="field col">
              <label htmlFor="modo" style={{ fontWeight: "bold" }}>
                Fecha Deseada Entrega
              </label>
              <InputText
                type="date"
                value={fechaDeseada}
                onChange={(e) => setFechaDeseada(e.target.value)}
                min="1000-01-01"
                max="9999-12-31"
              />
            </div>
          </div>
          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="solicitante" style={{ fontWeight: "bold" }}>
                Usuario Solicitante - Departamento
              </label>
              {empresa !== null && (
                <DropdownPageSolicitante
                  empresa={empresa}
                  setSolicitanteSeleccionado={setSolicitanteSeleccionado}
                  solicitanteSeleccionado={solicitanteSeleccionado}
                  solicitantes={solicitantes}
                  setSolicitantes={setSolicitantes}
                  baseUrl={baseUrl}
                  clientId={props.clientId}
                />
              )}
            </div>
          </div>
          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="motivo" style={{ fontWeight: "bold" }}>
                Motivo de Compra
              </label>
              {(() => {
                if (motivosCompra !== null && motivoSeleccionado !== null) {
                  return (
                    <>
                      <Dropdown
                        id="motivo"
                        // options={motivosCompra.map(motivo => ({ label: motivo.descripcion, value: motivo.descripcion }))}
                        optionLabel="descripcion"
                        options={motivosCompra}
                        value={motivoSeleccionado}
                        onChange={(e) => setMotivoSeleccionado(e.value)}
                        placeholder="Seleccione una opción"
                      />
                    </>
                  );
                }
              })()}
            </div>
          </div>
          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="motivo" style={{ fontWeight: "bold" }}>
                Comentarios (Opcional)
              </label>
              <InputTextarea
                value={comentario}
                onChange={(e) => setComentario(e.target.value)}
                placeholder="Escriba sus comentarios ..."
                autoResize
                rows="3"
                cols="30"
                maxLength={150}
              />
            </div>
          </div>
          <div className="formgrid grid">
            <div className="field col">
              <Button
                label="Crear"
                onClick={submitForm}
                severity="success"
                icon="pi pi-check"
                style={{ margin: "1%" }}
              />
            </div>
            <div className="field col">
              <Button
                label="Cancelar"
                onClick={hideDialog}
                severity="danger"
                icon="pi pi-times"
                style={{ margin: "1%" }}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

const DropdownPageEmpresa = (props) => {
  useEffect(() => {
    (async () => {
      const data = sessionStorage.getItem("empresas");
      const parsedData = JSON.parse(data);
      props.setEmpresas(parsedData);
    })();
  }, []);

  const handleEmpresaChange = (e) => {
    // Validación de empresa
    if (!props.empresa) {
      alert("Por favor seleccione una empresa.");
      return;
    }

    // Validación de fechas
    if (!props.fechaDesdeSolicitudes || !props.fechaHastaSolicitudes) {
      alert("Por favor seleccione fecha de inicio y término.");
      return;
    }

    // Validación de formato de fecha
    const fechaDesde = new Date(props.fechaDesdeSolicitudes);
    const fechaHasta = new Date(props.fechaHastaSolicitudes);
    if (isNaN(fechaDesde.getTime()) || isNaN(fechaHasta.getTime())) {
      alert("El formato de fecha es inválido.");
      return;
    }

    // Validación de rango de fechas
    if (fechaDesde > fechaHasta) {
      alert("La fecha de inicio no puede ser después de la de término.");
      return;
    }
    props.setEmpresa(e.value);
    // Save selected empresa to localStorage
    sessionStorage.setItem("selectedEmpresa", JSON.stringify(e.value));
  };
  return (
    <>
      <Dropdown
        value={props.empresa}
        options={props.empresas}
        onChange={(e) => handleEmpresaChange(e)}
        optionLabel="nombre"
        placeholder="Seleccione Empresa"
        className="mr-2"
        disabled={props.loading}
      />
    </>
  );
};

const DropdownPageSolicitante = (props) => {
  const endpoint =
    props.baseUrl + "getUsuariosSolicitantes?empresa=" + props.empresa.id;
  //asd

  useEffect(() => {
    (async () => {
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: new Headers(),
      }).then((res) => res.json());
      const matchingItem = data.usuariosSolicitantes.find((item) => {
        const nameFromUsuarioDpto = item.usuario.split(" - ")[0];
        return nameFromUsuarioDpto === props.clientId;
      });

      if (matchingItem) {
        // If a match is found, set props.setSolicitanteSeleccionado to the matching item
        props.setSolicitanteSeleccionado(matchingItem);
      } else {
        // If no match is found, set props.setSolicitanteSeleccionado to data[0]
        props.setSolicitanteSeleccionado(data.usuariosSolicitantes[0]);
      }

      props.setSolicitantes(data.usuariosSolicitantes);
    })();
  }, []);

  return (
    <div>
      <Dropdown
        id="solicitante"
        value={props.solicitanteSeleccionado}
        options={props.solicitantes}
        onChange={(e) => props.setSolicitanteSeleccionado(e.value)}
        optionLabel="usuario"
        placeholder="Seleccione solicitante"
      />
    </div>
  );
};

const TablaSolicitudes = (props) => {
  const endpoint = `${props.baseUrl}getListaSolicitudes?id_empresa=${props.empresa.id}&id_usuario=${props.usuario_id}&inicio=${props.fechaDesdeSolicitudes}&termino=${props.fechaHastaSolicitudes}&estado=`;
  const endpointContador = `${props.baseUrl}getContadores?id_empresa=${props.empresa.id}&id_usuario=${props.usuario_id}&inicio=${props.fechaDesdeSolicitudes}&termino=${props.fechaHastaSolicitudes}`;

  const fetchData = async () => {
    try {
      props.setLoading(true);
      const [contadoresResponse, solicitudesResponse] = await Promise.all([
        fetch(endpointContador, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
        }).then((res) => res.json()),
        fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
        }).then((res) => res.json()),
      ]);

      const contadores = contadoresResponse.contadores;
      const solicitudes = solicitudesResponse.solicitudes;
      props.setSolicitudes(solicitudes);
      props.setIngresada(contadores[0].cantidad);
      props.setRevision(contadores[1].cantidad);
      props.setAprobacion(contadores[2].cantidad);
      props.setCompra(contadores[3].cantidad);
      props.setRechazada(contadores[4].cantidad);
      props.setFinalizada(contadores[5].cantidad);
      props.setEliminada(contadores[6].cantidad);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      props.setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [props.empresa]);

  const filters = {
    id: { value: null, matchMode: "startsWith" },
    orden_compra: { value: null, matchMode: "startsWith" },
    estado: { value: null, matchMode: "equals" },
    solicitante: { value: null, matchMode: "startsWith" },
    departamento: { value: null, matchMode: "startsWith" },
    fecha_ingreso: { value: null, matchMode: "startsWith" },
    tipo: { value: null, matchMode: "startsWith" },
    proveedor: { value: null, matchMode: "startsWith" },
    aprobador: { value: null, matchMode: "startsWith" },
    total: { value: null, matchMode: "gt" },
    moneda: { value: null, matchMode: "equals" },
  };

  const { baseUrl } = useGlobalContext();
  const navigate = useNavigate();

  const handleRowClick = (rowData) => {
    const id = rowData.id;
    navigate(`/solicitud?id=${id}`);
  };

  const handleDownloadPDF = async (idSolicitud) => {
    try {
      const endpointPdf = baseUrl + "pdfOC?idSolicitud=" + idSolicitud;

      const response = await fetch(endpointPdf, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      });

      // Verificar que el estado HTTP sea entre 200 y 299
      if (!response.ok) {
        throw new Error(`Failed to fetch PDF. Status: ${response.status}`);
      }

      // Obtener el Blob de la respuesta
      const blob = await response.blob();

      // Crear una URL para el Blob y abrirla en una nueva ventana
      const url = window.URL.createObjectURL(blob);
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        {rowData.estado === "Compra" || rowData.estado === "Finalizada" ? (
          <>
            {" "}
            <Button
              className="p-button"
              label="Ver OC"
              onClick={() => handleDownloadPDF(rowData.id)}
            ></Button>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const statusBodyTemplate = (rowData) => {
    const statusClasses = {
      Ingresada: "status-new",
      Revisión: "status-renewal",
      Aprobación: "status-renewal",
      Compra: "status-qualified",
      Finalizada: "status-proposal",
      Rechazada: "status-unqualified",
      Eliminada: "status-unqualified",
    };

    const className = `customer-badge ${statusClasses[rowData.estado] || ""}`;
    return <span className={className}>{rowData.estado}</span>;
  };

  const currencyFormatter = (value) => {
    return new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
      currencyDisplay: "symbol",
    }).format(value);
  };

  const customHeaderStyle = { backgroundColor: "#e0dddc" };

  const getMonedaIconClass = (moneda) => {
    // Map moneda values to their corresponding labels
    const monedaIcons = {
      CLP: "pi pi-money-bill", // Dollar sign icon for Chilean Pesos
      USD: "pi pi-dollar", // Dollar sign icon for US Dollars
      UF: "pi pi-chart-line", // Money bill icon for Unidad de Fomento
      EUROA: "pi pi-euro", // Euro icon
      USDA: "pi pi-dollar", // Dollar sign icon for USDA
      null: "pi pi-question", // Question mark icon for null values
    };

    return monedaIcons[moneda];
  };

  const monedaBodyTemplate = (rowData) => {
    const monedaIconClass = getMonedaIconClass(rowData.moneda);
    return (
      <span>
        {rowData.moneda && (
          <span>
            <i className={monedaIconClass}></i> {rowData.moneda}
          </span>
        )}
      </span>
    );
  };
  const rowClassName = (rowData) => {
    return {
      "cursor-pointer": true, // Add your custom class here
    };
  };
  return (
    <div>
      {props.loading ? (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="4"
          />
        </div>
      ) : (
        <DataTable
          value={props.solicitudes}
          responsiveLayout="scroll"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          dataKey="id"
          paginator
          emptyMessage="No se encuentran registros asociados"
          className="datatable-responsive"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} solicitudes"
          rows={10}
          rowHover
          showGridlines
          onRowClick={(e) => handleRowClick(e.data)}
          rowClassName={rowClassName}
        >
          <Column
            field="id"
            sortable
            filter
            header="Folio"
            headerStyle={customHeaderStyle}
            filterValue={filters["id"].value}
            onFilter={(e) => (filters["id"].value = e.value)}
          ></Column>
          <Column
            field="orden_compra"
            sortable
            filter
            header="Orden Compra"
            headerStyle={customHeaderStyle}
            filterValue={filters["orden_compra"].value}
            onFilter={(e) => (filters["orden_compra"].value = e.value)}
          ></Column>
          <Column
            field="estado"
            sortable
            filter
            header="Estado"
            body={statusBodyTemplate}
            headerStyle={customHeaderStyle}
            filterValue={filters["estado"].value}
            onFilter={(e) => (filters["estado"].value = e.value)}
          ></Column>
          <Column
            field="solicitante"
            sortable
            filter
            header="Solicitante"
            headerStyle={customHeaderStyle}
            filterValue={filters["solicitante"].value}
            onFilter={(e) => (filters["solicitante"].value = e.value)}
          ></Column>
          <Column
            field="departamento"
            sortable
            filter
            header="Departamento"
            headerStyle={customHeaderStyle}
            filterValue={filters["departamento"].value}
            onFilter={(e) => (filters["departamento"].value = e.value)}
          ></Column>
          <Column
            field="fecha_ingreso"
            sortable
            filter
            header="Ingreso"
            headerStyle={customHeaderStyle}
            filterValue={filters["fecha_ingreso"].value}
            onFilter={(e) => (filters["fecha_ingreso"].value = e.value)}
          ></Column>
          <Column
            field="tipo"
            sortable
            filter
            header="Tipo"
            headerStyle={customHeaderStyle}
            filterValue={filters["tipo"].value}
            onFilter={(e) => (filters["tipo"].value = e.value)}
          ></Column>
          <Column
            field="proveedor"
            sortable
            filter
            header="Proveedor"
            headerStyle={customHeaderStyle}
            filterValue={filters["proveedor"].value}
            onFilter={(e) => (filters["proveedor"].value = e.value)}
          ></Column>
          <Column
            field="aprobador"
            sortable
            filter
            header="Aprobador"
            headerStyle={customHeaderStyle}
            filterValue={filters["aprobador"].value}
            onFilter={(e) => (filters["aprobador"].value = e.value)}
          ></Column>
          <Column
            field="total"
            sortable
            filter
            header="Total"
            body={(rowData) => currencyFormatter(rowData.total)}
            headerStyle={customHeaderStyle}
            filterValue={filters["total"].value}
            onFilter={(e) => (filters["total"].value = e.value)}
          ></Column>
          <Column
            field="moneda"
            sortable
            filter
            header="Moneda"
            headerStyle={customHeaderStyle}
            filterValue={filters["moneda"].value}
            onFilter={(e) => (filters["moneda"].value = e.value)}
            body={monedaBodyTemplate}
          ></Column>
          <Column
            body={(rowData) => actionBodyTemplate(rowData)}
            headerStyle={customHeaderStyle}
          ></Column>
        </DataTable>
      )}
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.colorMode === nextProps.colorMode &&
    +prevProps.isNewThemeLoaded === nextProps.isNewThemeLoaded &&
    prevProps.onNewThemeChange === nextProps.onNewThemeChange
  );
};

export default React.memo(Solicitudes, comparisonFn);
