import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressSpinner } from "primereact/progressspinner";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";

import { Button } from "primereact/button";
import { format } from "date-fns";

import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { useGlobalContext } from "../Config";
import "../assets/ultimate.css";
import debounce from "lodash/debounce";
import { classNames } from "primereact/utils";

// import TablaSolicitudes from './TablaSolicitudes';

const Solicitudes = (props) => {
  const { headerText } = useGlobalContext();
  const { baseUrl } = useGlobalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const id = params.get("id") || "Default value for param1";

  const [solicitud, setSolicitud] = useState(null);
  const [bodegaSolicitud, setBodegaSolicitud] = useState(null);
  const [solicitudProductos, setSolicitudProductos] = useState();
  const [solicitudProveedores, setSolicitudProveedores] = useState(null);
  const [productos, setProductos] = useState(null);
  const [proveedores, setProveedores] = useState(null);
  const [tasaHoy, setTasaHoy] = useState(null);
  const [codigoRapido, setCodigoRapido] = useState("");
  const [comentario, setComentario] = useState();
  const [comentarios, setComentarios] = useState(null);
  const [archivos, setArchivos] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [encodedFile, setEncodedFile] = useState(null);
  const [seleccionado, setSeleccionado] = useState(0);
  const [monedaSeleccionado, setMonedaSeleccionado] = useState(null);
  const [fechaEditar, setFechaEditar] = useState(null);
  const [fechaOC, setFechaOC] = useState(null);
  const [comentarioOC, setComentarioOC] = useState("");
  const [condicion, setCondicion] = useState(null);
  const [condiciones, setCondiciones] = useState(null);
  const [bodega, setBodega] = useState(null);
  const [bodegas, setBodegas] = useState(null);
  const [showWarningFecha, setShowWarningFecha] = useState(false);
  const [resultadoInyeccion, setResultadoInyeccion] = useState(null);
  const [loading, setLoading] = useState(false);

  const endpointSolicitud = baseUrl + "getSolicitud?id_solicitud=" + id;
  const endpointComentarios = baseUrl + "getComentarios?id_solicitud=" + id;
  const endpointArchivos = baseUrl + "getDocumentos?id_solicitud=" + id;

  const [visibleProducto, setVisibleProducto] = useState(false);
  const [visibleProveedor, setVisibleProveedor] = useState(false);
  const [visibleEditar, setVisibleEditar] = useState(false);
  const [visibleOC, setVisibleOC] = useState(false);
  const [moreThanOneArchivoCotizacion, setMoreThanOneArchivoCotizacion] =
    useState(false);
  const [maxArchivosCotizaciones, setMaxArchivosCotizaciones] = useState(0);
  const [monedaExtranjera, setMonedaExtranjera] = useState(false);
  const monedaInternacional = [
    { label: "Dolar Americano", value: "USDA" },
    { label: "Euro", value: "EUROA" },
  ];

  const monedaNacional = [
    { label: "Pesos Chilenos", value: "CLP" },
    { label: "Dolar Americano", value: "USD" },
    { label: "Unidad de Fomento", value: "UF" },
  ];

  const [isSaved, setIsSaved] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetch(endpointSolicitud, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: new Headers(),
        }).then((res) => res.json());

        // Set the initial value using data from the fetched JSON object
        setBodegaSolicitud(data.bodega);
        setSolicitud(data.solicitud);
        processMoneda(data.solicitud.moneda);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    checkMaxSolicitudes();
    checkMonedaExtranjera();
  }, [endpointSolicitud, solicitudProveedores, solicitud?.moneda]); // Added dependency to avoid linting warning

  const checkMaxSolicitudes = () => {
    if (solicitud?.modo === "Licitada") {
      setMoreThanOneArchivoCotizacion(true);
    } else {
      setMoreThanOneArchivoCotizacion(false);
    }
  };
  const checkMonedaExtranjera = () => {
    if (
      solicitud?.moneda === "USD" ||
      solicitud?.moneda === "USDA" ||
      solicitud?.moneda === "EUROA" ||
      solicitud?.moneda === "UF"
    ) {
      setMonedaExtranjera(true);
    } else {
      setMonedaExtranjera(false);
    }
  };
  useEffect(() => {
    const fetchData2 = async () => {
      try {
        const data = await fetch(endpointComentarios, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: headerText,
        }).then((res) => res.json());

        // Set the initial value using data from the fetched JSON object
        setComentarios(data.comentarios);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData2();
  }, [endpointComentarios]); // Added dependency to avoid linting warning
  const fetchData3 = async () => {
    try {
      const data = await fetch(endpointArchivos, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: new Headers(),
      }).then((res) => res.json());

      // Set the initial value using data from the fetched JSON object
      setArchivos(data.documentos);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData3();
  }, [endpointArchivos]); // Added dependency to avoid linting warning
  const processMoneda = async (moneda) => {
    const endpointTasaHoy = baseUrl + "getTasaHoy?moneda=" + moneda;

    try {
      const data = await fetch(endpointTasaHoy, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: new Headers(),
      }).then((res) => res.json());

      setTasaHoy(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const checkAmountOfCotizacionesPorSubir = () => {
    let cantidadPorSubir = 0;
    let cantidadProveedores = solicitudProveedores?.length;
    let cantidadArchivos = archivos?.length;
    cantidadPorSubir = cantidadProveedores - cantidadArchivos;
    setMaxArchivosCotizaciones(cantidadPorSubir);
  };
  useEffect(() => {
    if (solicitud) {
      if (solicitud?.modo === "Licitada") {
        checkAmountOfCotizacionesPorSubir();
      }
    }
  }, [solicitudProveedores]);

  const processBodegas = async (empresa) => {
    const endpoint = baseUrl + "getBodegas?id_empresa=" + empresa;

    try {
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: new Headers(),
      }).then((res) => res.json());

      setBodegas(data.bodegas);
      setBodega(data.bodegas[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const processCondiciones = async (empresa) => {
    const endpoint = baseUrl + "getCondiciones?id_empresa=" + empresa;

    try {
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: new Headers(),
      }).then((res) => res.json());

      setCondiciones(data.condicionesPago);
      setCondicion(solicitud.condicionPago);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const statusBodyTemplate = (estado) => {
    if (estado === "Ingresada") {
      return <span className={`customer-badge status-new`}>{estado}</span>;
    } else if (estado === "Revisión") {
      return <span className={`customer-badge status-renewal`}>{estado}</span>;
    } else if (estado === "Aprobación") {
      return <span className={`customer-badge status-renewal`}>{estado}</span>;
    } else if (estado === "Compra") {
      return (
        <span className={`customer-badge status-qualified`}>{estado}</span>
      );
    } else if (estado === "Finalizada") {
      return <span className={`customer-badge status-proposal`}>{estado}</span>;
    } else if (estado === "Rechazada") {
      return (
        <span className={`customer-badge status-unqualified`}>{estado}</span>
      );
    } else if (estado === "Eliminada") {
      return (
        <span className={`customer-badge status-unqualified`}>{estado}</span>
      );
    }
  };

  const statusPublicar = (estado) => {
    if (estado === "PROCESANDO ORDEN") {
      return <span className={`customer-badge status-proposal`}>{estado}</span>;
    } else if (estado === "ORDEN INYECTADA") {
      return (
        <span className={`customer-badge status-qualified`}>{estado}</span>
      );
    } else if (estado === "ERROR EN LA INYECCION") {
      return (
        <span className={`customer-badge status-unqualified`}>{estado}</span>
      );
    }
  };

  const currencyFormatter = (value, moneda) => {
    return new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
      minimumFractionDigits: 2, // No decimal digits for whole numbers
      maximumFractionDigits: 2, // Maximum of 2 decimal digits
      minimumIntegerDigits: 1, // minimum number of digits before the decimal separator
      useGrouping: true, // use grouping separator (thousand separator)
    }).format(value);
  };

  const currencyFormatterForCabecera = (value, moneda) => {
    return new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
      minimumFractionDigits: 2, // No decimal digits for whole numbers
      maximumFractionDigits: 2, // Maximum of 2 decimal digits
      minimumIntegerDigits: 1, // minimum number of digits before the decimal separator
      useGrouping: true, // use grouping separator (thousand separator)
    }).format(value);
  };

  const getMayorFecha = () => {
    const formatFecha = (fecha) => {
      const [day, month, year] = fecha.split("/");
      const dateObject = new Date(`${year}-${month}-${day}`);
      dateObject.setDate(dateObject.getDate() + 1);
      return dateObject;
    };

    if (typeof solicitudProductos !== "undefined") {
      const maxFecha = solicitudProductos.reduce((max, obj) => {
        const fechaPrometida = formatFecha(obj.fechaPrometida);
        return fechaPrometida > max ? fechaPrometida : max;
      }, formatFecha(solicitudProductos[0].fechaPrometida));

      return maxFecha;
    }
  };

  const showDialogProducto = async () => {
    if (!isSaved) {
    }

    if (solicitudProductos?.length >= 37) {
      alert("No puede agregar más productos");
      return;
    } else {
      setVisibleProducto(true);
    }
  };

  const hideDialogProducto = () => {
    setVisibleProducto(false);
  };

  const showDialogProveedor = () => {
    setVisibleProveedor(true);
  };

  const hideDialogProveedor = () => {
    setVisibleProveedor(false);
  };

  const showDialogEditar = async () => {
    if (!isSaved) {
    }
    setVisibleEditar(true);
  };

  const hideDialogEditar = () => {
    setVisibleEditar(false);
  };

  const showDialogOC = async () => {
    let date = getMayorFecha();
    var day = date.getDate();
    var month = date.getMonth() + 1; // Months are zero-based
    var year = date.getFullYear();

    // Ensure day and month are formatted with leading zeros if necessary
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;

    // Concatenate the components in the desired format
    var formattedDate = day + "/" + month + "/" + year;
    setFechaOC(formattedDate);
    processBodegas(solicitud.empresaId);
    processCondiciones(solicitud.empresaId);
    setVisibleOC(true);
  };

  const hideDialogOC = () => {
    setVisibleOC(false);
  };

  const submitFormRapido = async (codigo) => {
    if (!codigo) {
      alert("El código no puede estar vacío.");
      return;
    }
    if (solicitudProductos?.length >= 37) {
      alert("No puede agregar más productos");
      return;
    }
    if (!isSaved) {
    }

    setLoading(true);
    const endpoint =
      baseUrl + "agregarProducto?id_solicitud=" + id + "&codigo=" + codigo;
    try {
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: headerText,
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to create the request");
      }

      processDataRapido(data.respuesta);
    } catch (error) {
      console.error("Error:", error.message);
      // Handle the error (e.g., show an error message to the user)
    }
    setLoading(false);
  };

  const processDataRapido = (respuesta) => {
    const endpoint = baseUrl + "getSolicitudProducto?id_solicitud=" + id;
    const fetchData = async () => {
      try {
        const data = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: new Headers(),
        }).then((res) => res.json());

        setSolicitudProductos(data.solicitudProductos);
        setCodigoRapido("");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (respuesta.respuesta) {
      if (
        respuesta.mensaje !== "" &&
        typeof respuesta.mensaje !== "undefined"
      ) {
        alert(respuesta.mensaje);
      }
      fetchData();
    } else {
      fetchData();
      if (
        respuesta.mensaje !== "" &&
        typeof respuesta.mensaje !== "undefined"
      ) {
        alert(respuesta.mensaje);
      }
    }
  };

  const submitFormComentario = async (comentario) => {
    // Construct the query parameters using URLSearchParams
    const params = new URLSearchParams();
    params.append("id_solicitud", id);
    params.append("comentario", comentario);
    params.append("id_usuario", props.usuario_id);

    // Create the full URL with encoded parameters
    const endpoint = `${baseUrl}agregarComentario?${params.toString()}`;

    try {
      const data = await fetch(endpoint, {
        method: "POST",
        credentials: "include",
        redirect: "follow",
        headers: headerText,
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to create the request");
      }

      processDataComentario(data.respuesta);
    } catch (error) {
      console.error("Error:", error.message);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const processDataComentario = (respuesta) => {
    const endpoint = baseUrl + "getComentarios?id_solicitud=" + id;
    const fetchData = async () => {
      try {
        const data = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: headerText,
        }).then((res) => res.json());

        setComentarios(data.comentarios);
        setComentario("");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (respuesta) {
      fetchData();
    }
  };
  const confirmarSolicitud = async () => {
    if (!isSaved) {
    }

    const isConfirmed = window.confirm("Desea confirmar la solicitud?");
    if (isConfirmed) {
      setLoading(true);
      let hasArchivoFalse = false;
      if (solicitud?.modo === "Licitada") {
        hasArchivoFalse = solicitudProveedores.some((item) => !item.archivos);
      }

      if (hasArchivoFalse) {
        alert("Ingrese cotización para todos los proveedores");
        setLoading(false);
        return;
      }
      const endpoint =
        baseUrl +
        "confirmarSolicitud?id_solicitud=" +
        id +
        "&id_usuario=" +
        props.usuario_id;
      try {
        const data = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: new Headers(),
        }).then((res) => res.json());

        if (!data.respuesta || data.mensaje !== "OK") {
          alert(data.mensaje);
        } else {
          processDataConfirmar(data.respuesta);
        }
      } catch (error) {
        console.error("Error:", error.message);
        // Handle the error (e.g., show an error message to the user)
      }
      setLoading(false);
    }
  };

  const processDataConfirmar = (respuesta) => {
    const endpoint = baseUrl + "getSolicitud?id_solicitud=" + id;
    const fetchData = async () => {
      try {
        const data = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: new Headers(),
        }).then((res) => res.json());

        setBodegaSolicitud(data.bodega);
        setSolicitud(data.solicitud);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (respuesta.respuesta) {
      if (
        respuesta.mensaje !== "" &&
        typeof respuesta.mensaje !== "undefined"
      ) {
        alert(respuesta.mensaje);
      }
      fetchData();
    } else {
      fetchData();
      if (
        respuesta.mensaje !== "" &&
        typeof respuesta.mensaje !== "undefined"
      ) {
        alert(respuesta.mensaje);
      }
    }
  };

  const crearOC = async () => {
    const isConfirmed = window.confirm("Desea enviar la solicitud de OC?");
    if (isConfirmed) {
      hideDialogOC();
      setLoading(true);

      // Encode parameters
      const encodedId = encodeURIComponent(id);
      const encodedFechaOC = encodeURIComponent(fechaOC);
      const encodedCodigoBodega = encodeURIComponent(bodega.codigo);
      const encodedCondicion = encodeURIComponent(condicion);
      const encodedComentarioOC = encodeURIComponent(comentarioOC);

      // Construct the endpoint URL with encoded parameters
      const endpoint =
        baseUrl +
        "revisadaSolicitud?id_solicitud=" +
        encodedId +
        "&fechaPrometida=" +
        encodedFechaOC +
        "&codigoBodega=" +
        encodedCodigoBodega +
        "&condicion=" +
        encodedCondicion +
        "&comentarioOrdenCompra=" +
        encodedComentarioOC;

      try {
        const data = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: headerText,
        }).then((res) => res.json());

        if (data.mensaje !== "OK") {
          throw new Error("Failed to create the request");
        }
        processDataCrearOC(data.respuesta);
        navigate("/solicitudes");
      } catch (error) {
        console.error("Error:", error.message);
        // Handle the error (e.g., show an error message to the user)
      }
      setLoading(false);
    }
  };

  const processDataCrearOC = (respuesta) => {
    const endpoint = baseUrl + "getSolicitud?id_solicitud=" + id;
    const fetchData = async () => {
      try {
        const data = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: headerText,
        }).then((res) => res.json());

        setBodegaSolicitud(data.bodega);
        setSolicitud(data.solicitud);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (respuesta.respuesta) {
      if (
        respuesta.mensaje !== "" &&
        typeof respuesta.mensaje !== "undefined"
      ) {
        alert(respuesta.mensaje);
      }
      fetchData();
    } else {
      fetchData();
      if (
        respuesta.mensaje !== "" &&
        typeof respuesta.mensaje !== "undefined"
      ) {
        alert(respuesta.mensaje);
      }
    }
  };

  const editarSolicitud = async () => {
    setLoading(true);
    let moneda;
    let fecha;
    if (monedaSeleccionado === null) {
      moneda = solicitud.moneda;
    } else {
      moneda = monedaSeleccionado;
    }
    if (fechaEditar === null) {
      fecha = solicitud.fecha_entrega;
    } else {
      fecha = format(fechaEditar, "dd/MM/yyyy");
    }
    const endpoint =
      baseUrl +
      "editarSolicitud?id_solicitud=" +
      id +
      "&moneda=" +
      moneda +
      "&fecha_entrega=" +
      fecha;
    try {
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: new Headers(),
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to create the request");
      }

      processDataEditar(data.respuesta);
    } catch (error) {
      console.error("Error:", error.message);
      // Handle the error (e.g., show an error message to the user)
    }
    setLoading(false);
  };

  const processDataEditar = (respuesta) => {
    const endpoint = baseUrl + "getSolicitud?id_solicitud=" + id;
    const fetchData = async () => {
      try {
        const data = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: new Headers(),
        }).then((res) => res.json());

        setBodegaSolicitud(data.bodega);

        setSolicitud(data.solicitud);
        processMoneda(data.solicitud.moneda);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (respuesta.respuesta) {
      if (
        respuesta.mensaje !== "" &&
        typeof respuesta.mensaje !== "undefined"
      ) {
        alert(respuesta.mensaje);
      }
      fetchData();
      hideDialogEditar();
    } else {
      fetchData();
      hideDialogEditar();

      if (
        respuesta.mensaje !== "" &&
        typeof respuesta.mensaje !== "undefined"
      ) {
        alert(respuesta.mensaje);
      }
    }
  };
  const aprobarSolicitud = async () => {
    const isConfirmed = window.confirm("Desea aprobar la solicitud?");
    if (isConfirmed) {
      let idUsuario = JSON.parse(sessionStorage.getItem("usuario_id"));
      setLoading(true);
      const endpoint =
        baseUrl +
        "aprobarSolicitud?id_solicitud=" +
        id +
        "&id_usuario=" +
        idUsuario;
      const endpointGetOc = `${baseUrl}getOrdenCompra?id_solicitud=${id}`;
      try {
        const data = await fetch(endpoint, {
          method: "POST",
          credentials: "include",
          redirect: "follow",
          headers: new Headers(),
        }).then((res) => res.json());

        if (data.mensaje !== "OK") {
          throw new Error("Failed to create the request");
        }

        if (data.siguiente !== null) {
          alert(
            `Gracias por haber aprobado, ahora le toca aprobar a ${data.siguiente.nombre}`
          );
          navigate("/solicitudes");
        } else {
          alert("Se ha completado todo el flujo de aprobación");
          const ocResponse = await fetch(endpointGetOc, {
            method: "GET",
            credentials: "include",
            redirect: "follow",
            headers: new Headers(),
          }).then((res) => res.json());
          if (ocResponse.mensaje !== "OK") {
            throw new Error("Failed to fetch order details");
          }
          if (ocResponse.ordenCompra !== null) {
            navigate("/solicitudes");
          } else {
            alert("Su orden de compra se publicará en breves");
            navigate("/solicitudes");
          }
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
      setLoading(false);
    }
  };

  const processDataAprobar = () => {
    const endpoint = baseUrl + "getSolicitud?id_solicitud=" + id;
    const fetchData = async () => {
      try {
        const response = await fetch(endpoint);
        const jsonData = await response.json();
        setBodegaSolicitud(jsonData.bodega);
        setSolicitud(jsonData.solicitud);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  };

  const rechazarSolicitud = async () => {
    const isConfirmed = window.confirm("Desea rechazar la solicitud?");
    if (isConfirmed) {
      setLoading(true);
      const endpoint = baseUrl + "rechazarSolicitud?id_solicitud=" + id;
      try {
        const data = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: new Headers(),
        }).then((res) => res.json());

        if (data.mensaje !== "OK") {
          throw new Error("Failed to create the request");
        }

        processDataRechazar(data);
      } catch (error) {
        console.error("Error:", error.message);
        // Handle the error (e.g., show an error message to the user)
      }
      setLoading(false);
      navigate("/solicitudes");
    }
  };

  const processDataRechazar = (respuesta) => {
    const endpoint = baseUrl + "getSolicitud?id_solicitud=" + id;
    const fetchData = async () => {
      try {
        const data = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: new Headers(),
        }).then((res) => res.json());

        setBodegaSolicitud(data.bodega);

        setSolicitud(data.solicitud);
        setSeleccionado(0);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (respuesta.respuesta) {
      if (
        respuesta.mensaje !== "" &&
        typeof respuesta.mensaje !== "undefined"
      ) {
        alert(respuesta.mensaje);
      }
      fetchData();
    } else {
      fetchData();
      if (
        respuesta.mensaje !== "" &&
        typeof respuesta.mensaje !== "undefined"
      ) {
        alert(respuesta.mensaje);
      }
    }
  };

  const finalizarSolicitud = async () => {
    const isConfirmed = window.confirm(
      "Desea marcar como recibida la solicitud?"
    );
    if (isConfirmed) {
      setLoading(true);
      const endpoint = baseUrl + "finalizarSolicitud?id_solicitud=" + id;
      try {
        const data = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: new Headers(),
        }).then((res) => res.json());

        if (!data.respuesta || data.mensaje !== "OK") {
          alert(data.mensaje);
        } else {
          window.location.reload();
        }
      } catch (error) {
        console.error("Error:", error.message);
        // Handle the error (e.g., show an error message to the user)
      }
      setLoading(false);
    }
  };

  const processDataFinalizar = (respuesta) => {
    const endpoint = baseUrl + "getSolicitud?id_solicitud=" + id;
    const fetchData = async () => {
      try {
        const response = await fetch(endpoint);
        const jsonData = await response.json();
        setBodegaSolicitud(jsonData.bodega);

        setSolicitud(jsonData.solicitud);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (respuesta.respuesta) {
      if (
        respuesta.mensaje !== "" &&
        typeof respuesta.mensaje !== "undefined"
      ) {
        alert(respuesta.mensaje);
      }
      fetchData();
    } else {
      fetchData();
      if (
        respuesta.mensaje !== "" &&
        typeof respuesta.mensaje !== "undefined"
      ) {
        alert(respuesta.mensaje);
      }
    }
  };

  const borrarSolicitud = async () => {
    const endpoint = baseUrl + "borrarSolicitud?id_solicitud=" + id;
    try {
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: new Headers(),
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to create the request");
      }
    } catch (error) {
      console.error("Error:", error.message);
      // Handle the error (e.g., show an error message to the user)
    }
    navigate("/solicitudes");
  };

  const reingresarSolicitud = async () => {
    const endpoint = baseUrl + "reingresarSolicitud?id_solicitud=" + id;
    try {
      const data = await fetch(endpoint, {
        method: "POST",
        credentials: "include",
        redirect: "follow",
        headers: new Headers(),
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to reingresar solicitud");
      }

      alert("Solicitud reingresada con éxito");
      window.location.reload();
    } catch (error) {
      console.error("Error:", error.message);
      alert("Error al reingresar solicitud");
    }
  };

  const handleDownloadPDF = async () => {
    setLoading(true);
    try {
      const response = await fetch(baseUrl + "pdfOC?idSolicitud=" + id, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch PDF");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Open the PDF in a new window
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
    setLoading(false);
  };

  const bajarArchivo = async (idDocumento, nombreArchivo) => {
    setLoading(true);
    try {
      const response = await fetch(
        baseUrl + "descargar/adjunto/solicitud?id=" + idDocumento,
        {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: { "Content-Type": "application/json" },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to download file. Status: ${response.status}`);
      }
      const blob = await response.blob(); // Get the response body as Blob

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");

      const fileName =
        response.headers.get("Content-Disposition")?.split("=")[1] ||
        nombreArchivo;

      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
    setLoading(false);
  };

  const handleFileUpload = async (event) => {
    if (!isSaved) {
    }
    const file = event.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Data = btoa(e.target.result);

        // Get content type and file name
        const contentType = file.type;
        const fileName = file.name;
        const fileSize = file.size;

        // Now you can use the base64Data, contentType, and fileName as needed
        setEncodedFile(base64Data);
        subirArchivo(
          JSON.stringify({
            contentType: contentType,
            fileName: fileName,
            base64: base64Data,
            fileSize: fileSize,
            idSolicitud: id,
          })
        );
      };

      reader.readAsBinaryString(file);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      submitFormComentario(comentario);
    }
  };
  const subirArchivo = async (archivo) => {
    const endpoint = baseUrl + "adjuntarArchivoSolicitud";
    try {
      const data = await fetch(endpoint, {
        method: "POST",
        credentials: "include",
        redirect: "follow",
        body: archivo,
        headers: { "Content-Type": "application/json" },
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to create the request");
      }

      if (data.respuesta) {
        window.location.reload();
      } else {
        alert("Hubo un problema al subir el archivo");
      }
    } catch (error) {
      console.error("Error:", error.message);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const convertDateFormat = (inputDate) => {
    let parts = inputDate.split("/");
    if (parts.length === 3) {
      let day = parts[0];
      let month = parts[1];
      let year = parts[2];

      // Construct the new date string in "yyyy-mm-dd" format
      let formattedDate = year + "-" + month + "-" + day;
      return formattedDate;
    } else {
      // Handle invalid input date format
      console.error('Invalid date format. Expected "dd/mm/yyyy".');
      return null; // or throw an error, depending on your use case
    }
  };

  const convertDateFormat2 = (inputDate) => {
    let parts = inputDate.split("-");
    if (parts.length === 3) {
      let year = parts[0];
      let month = parts[1];
      let day = parts[2];

      // Construct the new date string in "dd/mm/yyyy" format
      let formattedDate = day + "/" + month + "/" + year;
      return formattedDate;
    } else {
      // Handle invalid input date format
      console.error('Invalid date format. Expected "yyyy-mm-dd".');
      return null; // or throw an error, depending on your use case
    }
  };
  const monedaIcons = {
    CLP: "pi pi-money-bill", // Dollar sign icon for Chilean Pesos
    USD: "pi pi-dollar", // Dollar sign icon for US Dollars
    UF: "pi pi-chart-line", // Money bill icon for Unidad de Fomento
    EUROA: "pi pi-euro", // Euro icon
    USDA: "pi pi-dollar", // Dollar sign icon for USDA
    null: "pi pi-question", // // Question mark icon for null values
  };
  const itemTemplate = (option) => {
    const iconClass = monedaIcons[option.value];

    return (
      <div className="p-d-flex p-ai-center">
        {iconClass && (
          <i
            className={classNames("pi", iconClass)}
            style={{ marginRight: "5px" }}
          ></i>
        )}
        <span>{option.label}</span>
      </div>
    );
  };

  const MonedaIcon = ({ moneda }) => {
    // Map moneda values to their corresponding PrimeIcons classes
    const monedaIcons = {
      CLP: "pi pi-money-bill", // Dollar sign icon for Chilean Pesos
      USD: "pi pi-dollar", // Dollar sign icon for US Dollars
      UF: "pi pi-chart-line", // Money bill icon for Unidad de Fomento
      EUROA: "pi pi-euro", // Euro icon
      USDA: "pi pi-dollar", // Dollar sign icon for USDA
      null: "pi pi-question", // Question mark icon for null values
    };

    const monedaIconClass = monedaIcons[moneda];

    return <i className={monedaIconClass} style={{ marginRight: "5px" }}></i>;
  };
  const checkFechaPrometida = (productosSolicitud, solicitud) => {
    if (Array.isArray(productosSolicitud) && productosSolicitud?.length > 0) {
      const fechaEntrega = parseDate(solicitud?.fecha_entrega); // Parse solicitud.fecha_entrega

      for (const producto of productosSolicitud) {
        const fechaPrometida = parseDate(producto?.fechaPrometida); // Parse producto.fechaPrometida

        if (fechaPrometida > fechaEntrega) {
          setShowWarningFecha(true);
          break;
        } else {
          setShowWarningFecha(false);
        }
      }
    }
  };
  const parseDate = (dateString) => {
    if (!dateString) return null; // Return null or handle appropriately if dateString is undefined

    const [day, month, year] = dateString.split("/");
    return new Date(`${year}-${month}-${day}`);
  };
  useEffect(() => {
    checkFechaPrometida(solicitudProductos, solicitud);
  }, [solicitudProductos]);

  const toast = useRef(null);
  const onUpload = () => {
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });
  };

  return (
    <div className="grid dashboard">
      {solicitud ? (
        <>
          <div className="col-12 md:col-12">
            <h5>
              Solicitud de Compra: #{id}{" "}
              {solicitud?.empresa ? `para ${solicitud?.empresa}` : ``}
            </h5>
            <div className="card p-fluid">
              <div className="formgrid grid">
                <div className="field col">
                  <label htmlFor="tipo" style={{ fontWeight: "bold" }}>
                    Estado
                  </label>
                  <br />
                  {statusBodyTemplate(solicitud?.estado)}
                </div>
                <div className="field col">
                  <label htmlFor="modo" style={{ fontWeight: "bold" }}>
                    Fecha Creación
                  </label>
                  <br />
                  <label>{solicitud?.fecha_ingreso}</label>
                </div>
                <div className="field col">
                  <label htmlFor="modo" style={{ fontWeight: "bold" }}>
                    Fecha Deseada Entrega
                  </label>
                  <br />
                  <label>{solicitud?.fecha_entrega}</label>
                </div>
              </div>
              <div className="formgrid grid">
                <div className="field col">
                  <label htmlFor="tipo" style={{ fontWeight: "bold" }}>
                    Usuario
                  </label>
                  <br />
                  <label>{solicitud?.usuario}</label>
                </div>
                <div className="field col">
                  <label htmlFor="modo" style={{ fontWeight: "bold" }}>
                    Solicitante
                  </label>
                  <br />
                  <label>{solicitud?.solicitante}</label>
                </div>
                <div className="field col">
                  <label htmlFor="modo" style={{ fontWeight: "bold" }}>
                    Departamento Solicitante
                  </label>
                  <br />
                  <label>{solicitud?.departamento}</label>
                </div>
              </div>
              <div className="formgrid grid">
                <div className="field col">
                  <label htmlFor="tipo" style={{ fontWeight: "bold" }}>
                    Aprobador
                  </label>
                  <br />
                  {(() => {
                    const content =
                      solicitud?.aprobador === solicitud?.usuario ? (
                        <label>No Necesita Aprobador</label>
                      ) : (
                        <label>{solicitud?.aprobador}</label>
                      );

                    return <>{content}</>;
                  })()}
                </div>
                <div className="field col">
                  {monedaExtranjera ? (
                    <>
                      <label htmlFor="modo" style={{ fontWeight: "bold" }}>
                        Total
                      </label>
                      <br />
                      <label>
                        {`${currencyFormatter(Number(solicitud?.total))} ${
                          solicitud?.moneda
                        }`}
                      </label>
                      <br />
                      <label>
                        {`${currencyFormatterForCabecera(
                          Number(solicitud?.total) *
                            Number(solicitud?.tasaCambio)
                        )} CLP`}
                      </label>
                    </>
                  ) : (
                    <>
                      <label htmlFor="modo" style={{ fontWeight: "bold" }}>
                        Total
                      </label>
                      <br />
                      <label>{currencyFormatter(solicitud?.total)}</label>
                    </>
                  )}
                </div>
                <div className="field col">
                  <label htmlFor="modo" style={{ fontWeight: "bold" }}>
                    Moneda
                  </label>
                  <br />
                  <div>
                    <MonedaIcon moneda={solicitud?.moneda} />
                    <label>{solicitud?.moneda}</label>
                  </div>{" "}
                </div>
              </div>
              <div className="formgrid grid">
                <div className="field col">
                  <label htmlFor="tipo" style={{ fontWeight: "bold" }}>
                    Tasa Cambio
                  </label>
                  <br />
                  <label>{currencyFormatter(solicitud?.tasaCambio)}</label>
                </div>
                <div className="field col">
                  <label htmlFor="modo" style={{ fontWeight: "bold" }}>
                    Proveedor Seleccionado
                  </label>
                  <br />
                  <label>{solicitud?.proveedor}</label>
                </div>
                <div className="field col">
                  <label htmlFor="modo" style={{ fontWeight: "bold" }}>
                    Condicion de Pago
                  </label>
                  <br />
                  <label>{solicitud?.condicionPago}</label>
                </div>
              </div>
              <div className="formgrid grid">
                <div className="field col">
                  <label htmlFor="tipo" style={{ fontWeight: "bold" }}>
                    Motivo Compra
                  </label>
                  <br />
                  <label>{solicitud?.motivo}</label>
                </div>
                <div className="field col">
                  <label htmlFor="modo" style={{ fontWeight: "bold" }}>
                    Modo Compra
                  </label>
                  <br />
                  <label>{solicitud?.modo}</label>
                </div>
                <div className="field col">
                  <label htmlFor="modo" style={{ fontWeight: "bold" }}>
                    Tipo Compra
                  </label>
                  <br />
                  <label>{solicitud?.tipo}</label>
                </div>
              </div>
              <div className="formgrid grid">
                <div className="field col">
                  <label htmlFor="tipo" style={{ fontWeight: "bold" }}>
                    Orden Compra
                  </label>
                  <br />
                  <label>{solicitud?.orden_compra}</label>
                </div>
                <div className="field col">
                  <label htmlFor="modo" style={{ fontWeight: "bold" }}>
                    Inyección Dynamics
                  </label>
                  <br />
                  {(() => {
                    if (
                      solicitud?.estadoId === 4 ||
                      solicitud?.estadoId === 6
                    ) {
                      return (
                        <>
                          <label>{statusPublicar(solicitud?.resultado)}</label>
                        </>
                      );
                    }
                  })()}
                </div>
                <div className="field col">
                  <label htmlFor="tipo" style={{ fontWeight: "bold" }}>
                    Bodega OC
                  </label>
                  <br />
                  <label>{bodegaSolicitud?.codigo}</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 md:col-12">
            {(() => {
              const buttons = [];

              const addButton = (label, severity, icon, onClick) => {
                buttons.push(
                  <Button
                    key={label}
                    label={label}
                    severity={severity}
                    icon={`pi ${icon}`}
                    onClick={onClick}
                    style={{ margin: "1%" }}
                    disabled={isSaving}
                  />
                );
              };

              if (solicitud.estadoId === 1) {
                addButton(
                  "Confirmar",
                  "warning",
                  "pi-check",
                  confirmarSolicitud
                );
                addButton(
                  "Editar",
                  "success",
                  "pi-file-edit",
                  showDialogEditar
                );
                addButton("Eliminar", "danger", "pi-trash", borrarSolicitud);
              } else if (solicitud.estadoId === 2) {
                addButton(
                  "Crear Orden de Compra",
                  "warning",
                  "pi-plus",
                  showDialogOC
                );
                addButton("Eliminar", "danger", "pi-trash", borrarSolicitud);
                addButton(
                  "Reingresar Solicitud",
                  "info",
                  "pi-backward",
                  reingresarSolicitud
                );
              } else if (
                solicitud.estadoId === 3 &&
                props.privilegios.includes(41) &&
                props.usuarioLogeado === solicitud.aprobador
              ) {
                addButton("Aprobar", "success", "pi-check", aprobarSolicitud);
                addButton("Rechazar", "danger", "pi-times", rechazarSolicitud);
              } else if (solicitud.estadoId === 4) {
                addButton(
                  "Recibido",
                  "warning",
                  "pi-check",
                  finalizarSolicitud
                );
                addButton("Ver OC", "success", "pi-search", handleDownloadPDF);
              } else if (solicitud.estadoId === 6) {
                addButton("Ver OC", "success", "pi-search", handleDownloadPDF);
              }

              return buttons;
            })()}
          </div>
          <div className="col-12 md:col-12">
            <Divider layout="horizontal" align="center" />
            {showWarningFecha && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "yellow",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    maxWidth: "750px",
                  }}
                >
                  <i className="pi pi-exclamation-triangle mr-2"></i>
                  <span>
                    Uno o más productos tiene una fecha prometida con el
                    proveedor mayor a la fecha deseada de entrega.
                  </span>
                </div>
              </div>
            )}
            <h5>
              {solicitud.estadoId === 1 && (
                <Button
                  icon="pi pi-plus"
                  className="p-button-sm"
                  style={{ marginRight: "10px" }}
                  onClick={showDialogProducto}
                  disabled={isSaving}
                />
              )}
              Productos
            </h5>

            {solicitud.estadoId === 1 && (
              <>
                <label style={{ fontWeight: "bold" }}>
                  Ingreso Rápido Producto:{" "}
                </label>
                <InputText
                  value={codigoRapido}
                  onChange={(e) => setCodigoRapido(e.target.value)}
                  type="text"
                  placeholder="Codigo"
                  disabled={isSaving}
                />
                <Button
                  label={"Agregar"}
                  className="p-button-sm"
                  onClick={() => submitFormRapido(codigoRapido)}
                  disabled={isSaving}
                />
              </>
            )}

            <div className="card p-fluid">
              <TablaProductos2
                baseUrl={baseUrl}
                convertDateFormat={convertDateFormat}
                convertDateFormat2={convertDateFormat2}
                solicitudProductos={solicitudProductos}
                setSolicitudProductos={setSolicitudProductos}
                seleccionado={seleccionado}
                id={id}
                setSolicitud={setSolicitud}
                privilegios={props.privilegios}
                solicitud={solicitud}
                setLoading={setLoading}
                isSaved={isSaved}
                setIsSaved={setIsSaved}
                isSaving={isSaving}
                setIsSaving={setIsSaving}
                checkFechaPrometida={checkFechaPrometida}
                setBodegaSolicitud={setBodegaSolicitud}
                bodegaSolicitud={bodegaSolicitud}
                headerText={headerText}
              />
            </div>
            {!isSaved && <></>}
            <Divider layout="horizontal" align="center" />
            <h5>
              {solicitud.estadoId === 1 && (
                <>
                  <Button
                    icon="pi pi-plus"
                    className="p-button-sm"
                    style={{ marginRight: "10px" }}
                    onClick={showDialogProveedor}
                    disabled={isSaving}
                  />
                </>
              )}
              Proveedores
            </h5>

            <div className="card p-fluid">
              <TablaProveedores
                baseUrl={baseUrl}
                id={id}
                solicitudProveedores={solicitudProveedores}
                setSolicitudProveedores={setSolicitudProveedores}
                setSeleccionado={setSeleccionado}
                solicitud={solicitud}
                solicitudProductos={solicitudProductos}
                setLoading={setLoading}
                maxArchivosCotizaciones={maxArchivosCotizaciones}
                setEncodedFile={setEncodedFile}
                isSaved={isSaved}
                headerText={headerText}
              />
            </div>
            <Divider layout="horizontal" align="center" />
            <div className="col-12">
              <div className="card">
                <div className="grid">
                  <div className="col-5 align-items-center justify-content-center">
                    <h5>Comentarios</h5>
                    <InputTextarea
                      value={comentario}
                      onChange={(e) => setComentario(e.target.value)}
                      onKeyPress={handleKeyPress}
                      placeholder="Escriba sus comentarios ..."
                      autoResize
                      rows="3"
                      cols="30"
                      maxLength={150}
                      style={{ width: "100%", marginBottom: "3%" }}
                    />
                    <Button
                      label={"Agregar comentario"}
                      className="p-button-sm"
                      onClick={() => submitFormComentario(comentario)}
                      disabled={isSaving}
                    />
                    <br />
                    <br />

                    {comentarios ? (
                      comentarios
                        .sort((a, b) => new Date(b.fecha) - new Date(a.fecha))
                        .map(({ id, comentario, fecha, usuario }) => (
                          <div key={id}>
                            <label style={{ fontWeight: "bold" }}>
                              {fecha} | {usuario}
                            </label>
                            <br />
                            <br />
                            <p>{comentario}</p>
                            <Divider layout="horizontal" align="center" />
                          </div>
                        ))
                        .reverse()
                    ) : (
                      <p>Obteniendo Comentarios...</p>
                    )}
                  </div>
                  <div className="col-1">
                    <Divider layout="vertical" />
                  </div>
                  <div className="col-5 align-items-center justify-content-center">
                    <h5>Archivos subidos para solicitud</h5>
                    {archivos ? (
                      archivos?.map(({ id, nombre }) => (
                        <div key={id}>
                          <Button
                            label={nombre}
                            onClick={() => bajarArchivo(id, nombre)}
                            className="text-primary p-button-text"
                            style={{ cursor: "pointer" }}
                          ></Button>
                          <Divider layout="horizontal" align="center" />
                        </div>
                      ))
                    ) : (
                      <p>Obteniendo Archivos ...</p>
                    )}
                    <FileUpload
                      chooseLabel="Elegir Archivo"
                      uploadLabel="Subir"
                      cancelLabel="Cancelar"
                      customUpload
                      uploadHandler={handleFileUpload}
                      disabled={isSaving}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Dialog
            header=""
            visible={loading}
            modal
            breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          >
            <div className="loading-screen">
              <ProgressBar
                mode="indeterminate"
                style={{ height: "6px" }}
              ></ProgressBar>
              <p>Procesando ...</p>
            </div>
          </Dialog>

          <Dialog
            header="Ingresar Producto"
            visible={visibleProducto}
            onHide={() => setVisibleProducto(false)}
            modal
            breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          >
            <div className="card p-fluid">
              <TablaProductosIngreso
                baseUrl={baseUrl}
                usuario_id={props.usuario_id}
                productos={productos}
                setProductos={setProductos}
                solicitud={solicitud}
                hideDialogProducto={hideDialogProducto}
                setSolicitudProductos={setSolicitudProductos}
                navigate={navigate}
                headerText={headerText}
              />
            </div>
          </Dialog>
          <Dialog
            header="Ingresar Proveedor"
            visible={visibleProveedor}
            onHide={() => setVisibleProveedor(false)}
            modal
            breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          >
            <div className="card p-fluid">
              <TablaProveedoresIngreso
                baseUrl={baseUrl}
                usuario_id={props.usuario_id}
                proveedores={proveedores}
                setProveedores={setProveedores}
                solicitud={solicitud}
                hideDialogProveedor={hideDialogProveedor}
                setSolicitudProveedores={setSolicitudProveedores}
                navigate={navigate}
              />
            </div>
          </Dialog>
          <Dialog
            header="Crear Orden de Compra"
            visible={visibleOC}
            onHide={() => setVisibleOC(false)}
            modal
            breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          >
            {(() => {
              if (
                solicitud.aprobador !== null &&
                solicitud.aprobador !== "" &&
                solicitud.aprobador !== solicitud.usuario
              ) {
                return (
                  <>
                    <p style={{ color: "red" }}>
                      - Después de creada la orden de compra quedara a la espera
                      del aprobador {solicitud.aprobador}
                    </p>
                  </>
                );
              }
              if (seleccionado === null) {
                return (
                  <>
                    <p style={{ color: "red" }}>
                      - Seleccione un proveedor antes de poder crear la orden de
                      compra
                    </p>
                  </>
                );
              }
              let fechasTotales = 0;
              if (typeof solicitudProductos !== "undefined") {
                for (let i = 0; i < solicitudProductos?.length; i++) {
                  if (solicitudProductos[i].fechaPrometida !== null) {
                    fechasTotales++;
                  }
                }
                if (fechasTotales < solicitudProductos.length) {
                  return (
                    <>
                      <p style={{ color: "red" }}>
                        - No todos los productos tienen una fecha prometida.
                        Seleccione una fecha general para los productos sin
                        fecha
                      </p>
                    </>
                  );
                }
              }
              let archivosTotales = 0;
              if (
                typeof solicitudProveedores !== "undefined" &&
                solicitudProveedores !== null
              ) {
                for (let i = 0; i < solicitudProveedores.length; i++) {
                  if (solicitudProveedores[i].archivo) {
                    archivosTotales++;
                  }
                }
                if (solicitud.modo === "Licitada" && archivosTotales > 0) {
                  return (
                    <>
                      <p style={{ color: "red" }}>
                        - La solicitud no posee archivos de cotizacion. Debe
                        subir al menos 1 archivo de cotización desde proveedor
                      </p>
                    </>
                  );
                }
              }
            })()}
            <div className="card p-fluid">
              <div className="formgrid grid">
                <div className="field col">
                  <label style={{ fontWeight: "bold" }}>
                    Nro. Orden de Compra
                  </label>
                  <p>Por Asignar</p>
                </div>
                <div className="field col">
                  <label style={{ fontWeight: "bold" }}>Bodegas</label>
                  {(() => {
                    if (bodegas !== null) {
                      return (
                        <>
                          <Dropdown
                            id="bodega"
                            options={bodegas.map((bodega) => ({
                              label: `${bodega.descripcion} (${bodega.codigo})`,
                              value: bodega,
                            }))}
                            value={bodega}
                            onChange={(e) => setBodega(e.value)}
                            placeholder="Seleccione Bodega"
                          />
                        </>
                      );
                    }
                  })()}
                </div>
              </div>
              <div className="formgrid grid">
                <div className="field col">
                  <label style={{ fontWeight: "bold" }}>Proveedor</label>
                  <p>{solicitud.proveedor}</p>
                </div>
                <div className="field col">
                  <label style={{ fontWeight: "bold" }}>
                    Condicion de Pago
                  </label>
                  {(() => {
                    if (condiciones !== null) {
                      return (
                        <>
                          <Dropdown
                            id="condicion"
                            options={condiciones.map((condicion) => ({
                              label: condicion.condicion,
                              value: condicion.condicion,
                            }))}
                            value={condicion}
                            onChange={(e) => setCondicion(e.value)}
                            placeholder="Seleccione Condicion"
                          />
                        </>
                      );
                    }
                  })()}
                </div>
              </div>
              <div className="formgrid grid">
                <div className="field col">
                  <label style={{ fontWeight: "bold" }}>
                    Fecha Prometida Proveedor
                  </label>
                  <p>{fechaOC}</p>
                </div>
                <div className="field col">
                  <label style={{ fontWeight: "bold" }}>Comentarios OC</label>
                  <InputTextarea
                    value={comentarioOC}
                    onChange={(e) => setComentarioOC(e.target.value)}
                    placeholder="Escriba sus comentarios ..."
                    autoResize
                    rows="4"
                    cols="30"
                    maxLength={250}
                  />
                </div>
              </div>
              <div className="formgrid grid">
                <div className="field col">
                  <Button
                    label="Comprar"
                    onClick={crearOC}
                    severity="success"
                    icon="pi pi-check"
                    style={{ margin: "1%" }}
                    disabled={isSaving}
                  />
                </div>
                <div className="field col">
                  <Button
                    label="Cancelar"
                    onClick={hideDialogOC}
                    severity="danger"
                    icon="pi pi-times"
                    style={{ margin: "1%" }}
                    disabled={isSaving}
                  />
                </div>
              </div>
            </div>
          </Dialog>
          <Dialog
            header="Editar Cabecera"
            visible={visibleEditar}
            onHide={() => setVisibleEditar(false)}
            modal
            breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          >
            <div className="card p-fluid">
              <div className="formgrid grid">
                <div className="field col">
                  <label style={{ fontWeight: "bold" }}>Moneda</label>
                  {solicitud.tipo === "Nacional" && (
                    <Dropdown
                      id="moneda"
                      options={monedaNacional}
                      value={monedaSeleccionado}
                      onChange={(e) => setMonedaSeleccionado(e.value)}
                      placeholder="Seleccione moneda"
                      itemTemplate={itemTemplate}
                    />
                  )}
                  {solicitud.tipo === "Internacional" && (
                    <Dropdown
                      id="moneda"
                      options={monedaInternacional}
                      value={monedaSeleccionado}
                      onChange={(e) => setMonedaSeleccionado(e.value)}
                      placeholder="Seleccione moneda"
                      itemTemplate={itemTemplate}
                    />
                  )}
                </div>
                <div className="field col">
                  <label htmlFor="modo" style={{ fontWeight: "bold" }}>
                    Fecha Deseada Entrega
                  </label>
                  <Calendar
                    dateFormat="dd/mm/yy"
                    showIcon
                    placeholder={solicitud.fecha_entrega}
                    value={solicitud.fecha_entrega}
                    onChange={(e) => setFechaEditar(e.value)}
                  ></Calendar>
                </div>
              </div>
              <div className="formgrid grid">
                <div className="field col">
                  <Button
                    label="Guardar"
                    onClick={editarSolicitud}
                    severity="success"
                    icon="pi pi-check"
                    style={{ margin: "1%" }}
                  />
                </div>
                <div className="field col">
                  <Button
                    label="Cancelar"
                    onClick={hideDialogEditar}
                    severity="danger"
                    icon="pi pi-times"
                    style={{ margin: "1%" }}
                  />
                </div>
              </div>
            </div>
          </Dialog>
        </>
      ) : (
        <p>Cargando Solicitud ...</p>
      )}
    </div>
  );
};

const TablaProductos2 = ({
  baseUrl,
  convertDateFormat,
  convertDateFormat2,
  solicitudProductos,
  setSolicitudProductos,
  seleccionado,
  id,
  setSolicitud,
  privilegios,
  solicitud,
  setLoading,
  isSaved,
  setIsSaved,
  isSaving,
  setIsSaving,
  checkFechaPrometida,
  setBodegaSolicitud,
  bodegaSolicitud,
  headerText,
}) => {
  const [cantidadProductos, setCantidadProductos] = useState(0);

  const endpoint = baseUrl + "getSolicitudProducto?id_solicitud=" + id;

  useEffect(() => {
    (async () => {
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: headerText,
      }).then((res) => res.json());
      setSolicitudProductos(data.solicitudProductos);
      setLoading(false);
    })();
  }, []);

  const filters = {
    id: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  };

  const customHeaderStyle = { backgroundColor: "#e0dddc" };

  const borrarProducto = async (codigo) => {
    const endpoint = baseUrl + "borrarProducto?id_solicitud_producto=" + codigo;
    try {
      const data = await fetch(endpoint, {
        method: "POST",
        credentials: "include",
        redirect: "follow",
        headers: new Headers(),
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to create the request");
      }

      processData(data.respuesta);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  const processData = async (respuesta) => {
    const endpoint = baseUrl + "getSolicitudProducto?id_solicitud=" + id;

    const fetchData = async () => {
      try {
        const data = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: headerText,
        }).then((res) => res.json());

        setSolicitudProductos(data.solicitudProductos);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (respuesta.respuesta) {
      if (
        respuesta.mensaje !== "" &&
        typeof respuesta.mensaje !== "undefined"
      ) {
        alert(respuesta.mensaje);
      }
      await fetchData();
    } else {
      await fetchData();
      if (
        respuesta.mensaje !== "" &&
        typeof respuesta.mensaje !== "undefined"
      ) {
        alert(respuesta.mensaje);
      }
    }
  };

  const handleBorradoProducto = async (id) => {
    await borrarProducto(id);
  };

  const actionBodyTemplate = (rowData) => {
    if (solicitud.estadoId === 1) {
      return (
        <Button
          onClick={() => handleBorradoProducto(rowData.id)}
          icon="pi pi-trash"
          severity="danger"
        />
      );
    }
  };

  const handleBlur = async (e, id, field) => {
    // Esta función se ejecutará cuando el usuario pierda el foco del campo de texto
    const value = e.target;
    let endpoint;
    let fechaAux;

    // Update the state with the new value
    const updatedProductos = solicitudProductos.map((producto) => {
      if (producto.id === id) {
        if (typeof producto.fechaPrometida === "undefined") {
          fechaAux = "NULL";
        } else {
          fechaAux = producto.fechaPrometida;
        }
        const params = new URLSearchParams({
          id_solicitud: id,
          nombreProducto: producto.nombreProducto,
          unidadSeleccionada: producto.unidadSeleccionada,
          cantidadOrdenada: producto.cantidadOrdenada,
          fechaPrometida: fechaAux,
          precio: producto.precio,
          id_solicitud_producto: producto.id,
          codigoProducto: producto.codigoProducto,
          cantidadRecibida: producto.cantidadRecibida,
        });

        switch (field) {
          case "nombreProducto":
            params.set("nombreProducto", value);
            break;
          case "unidadSeleccionada":
            params.set("unidadSeleccionada", value);
            break;
          case "cantidadOrdenada":
            params.set("cantidadOrdenada", value);
            break;
          case "cantidadRecibida":
            params.set("cantidadRecibida", value);
            break;
          case "precio":
            params.set("precio", value);
            break;
          case "fechaPrometida":
            params.set("fechaPrometida", convertDateFormat2(value));
            break;
          default:
            console.log("Sin coincidencia");
        }

        // Construct the full URL with encoded parameters
        endpoint = `${baseUrl}actualizarProductoSolicitud?${params.toString()}`;

        return { ...producto, [field]: value };
      }
      return producto;
    });

    // Set the updated state
    try {
      const data = await fetch(endpoint, {
        method: "POST",
        credentials: "include",
        redirect: "follow",
        headers: headerText,
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to create the request");
      }

      processData(data);
    } catch (error) {
      console.error("Error:", error.message);
      // Handle the error (e.g., show an error message to the user)
    }

    try {
      const endpointSolicitud = `${baseUrl}getSolicitud?id_solicitud=${id}`;
      const response = await fetch(endpointSolicitud, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: new Headers(),
      }).then((res) => res.json());

      setBodegaSolicitud(response.bodega);

      // Set the initial value using data from the fetched JSON object
      setSolicitud(response.solicitud);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleProductUpdate = async (rowData) => {
    try {
      const endPointSaveProducto = `${baseUrl}actualizarProductoSolicitud?id_solicitud=${encodeURIComponent(
        id
      )}&nombre_producto=${encodeURIComponent(
        rowData.nombreProducto
      )}&unidad_seleccionada=${encodeURIComponent(
        rowData.unidadSeleccionada
      )}&cantidad_ordenada=${encodeURIComponent(
        rowData.cantidadOrdenada
      )}&fecha_prometida=${encodeURIComponent(
        rowData.fechaPrometida
      )}&precio=${encodeURIComponent(
        rowData.precio
      )}&id_solicitud_producto=${encodeURIComponent(
        rowData.id
      )}&codigo_producto=${encodeURIComponent(
        rowData.codigoProducto
      )}&cantidad_recibida=${encodeURIComponent(rowData.cantidadRecibida)}`;
      const data = await fetch(endPointSaveProducto, {
        method: "POST",
        credentials: "include",
        redirect: "follow",
        headers: headerText,
      }).then((res) => res.json());

      if (data.respuesta) {
        setIsSaved(true);
        checkFechaPrometida(solicitudProductos, solicitud);
      }
    } catch (error) {}
  };

  const inputBodyDescripcion = (rowData) => {
    if (privilegios.includes(33) && solicitud.estadoId === 1) {
      return (
        <InputText
          type="text"
          value={rowData.nombreProducto}
          onChange={(e) =>
            handleInputChangeProductos(e, rowData.id, "nombreProducto", rowData)
          }
          onBlur={() => handleProductUpdate(rowData)}
          disabled={isSaving}
        />
      );
    } else {
      return <label>{rowData.nombreProducto}</label>;
    }
  };
  const inputBodyCantidad = (rowData) => {
    if (privilegios.includes(33) && solicitud.estadoId === 1) {
      return (
        <InputText
          value={rowData.cantidadOrdenada}
          autoFocus
          onKeyDown={(e) => handleKeyPress(e)}
          onChange={(e) =>
            handleInputChangeProductos(
              e,
              rowData.id,
              "cantidadOrdenada",
              rowData
            )
          }
          onBlur={() => handleProductUpdate(rowData)}
          disabled={isSaving}
          keyfilter="num" // Allow only numeric characters
        />
      );
    } else {
      return <label>{rowData.cantidadOrdenada}</label>;
    }
  };

  const inputBodyCantidadRecibida = (rowData) => {
    if (solicitud.estadoId === 4) {
      return (
        <InputText
          value={rowData.cantidadRecibida}
          onKeyDown={(e) => handleKeyPress(e)}
          onChange={(e) =>
            handleInputChangeProductos(
              e,
              rowData.id,
              "cantidadRecibida",
              rowData
            )
          }
          onBlur={() => handleProductUpdate(rowData)}
          disabled={isSaving}
          keyfilter="num" // Allow only numeric characters
        />
      );
    } else {
      return <label>{rowData.cantidadRecibida}</label>;
    }
  };

  const currencyFormatter = (value, moneda) => {
    return new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
      minimumFractionDigits: 2, // No decimal digits for whole numbers
      maximumFractionDigits: 2, // Maximum of 2 decimal digits
      minimumIntegerDigits: 1, // minimum number of digits before the decimal separator
      useGrouping: true, // use grouping separator (thousand separator)
    }).format(value);
  };

  function handleKeyPress(event) {
    if (event.key === "," || event.key === "comma") {
      // Prevent default behavior (typing comma)
      event.preventDefault();

      // Replace comma with dot in the input value
      const input = event.target;
      const selectionStart = input.selectionStart;
      const selectionEnd = input.selectionEnd;
      const value = input.value;

      input.value =
        value.substring(0, selectionStart) +
        "." +
        value.substring(selectionEnd);
      input.selectionStart = input.selectionEnd = selectionStart + 1;
    }
  }
  const inputBodyPrecio = (rowData) => {
    if (solicitud.estadoId === 1 && seleccionado === 1) {
      return (
        <InputText
          value={rowData.precio}
          onKeyDown={(e) => handleKeyPress(e)}
          onChange={(e) =>
            handleInputChangeProductos(e, rowData, "precio", rowData)
          }
          onBlur={() => handleProductUpdate(rowData)}
          disabled={isSaving}
          keyfilter="num" // Allow only numeric characters
        />
      );
    } else {
      return <label>{currencyFormatter(rowData.precio)}</label>;
    }
  };

  const dropDownUnidades = (rowData) => {
    if (privilegios.includes(33) && solicitud.estadoId === 1) {
      return (
        <Dropdown
          options={rowData.unidades}
          value={rowData.unidadSeleccionada}
          onChange={(e) =>
            handleInputChangeProductos(
              e,
              rowData.id,
              "unidadSeleccionada",
              rowData
            )
          }
          onBlur={() => handleProductUpdate(rowData)}
          //onBlur={(e) => debouncedHandleBlur(e, rowData.id, "unidadSeleccionada")}
          //disabled
        />
      );
    } else {
      return <label>{rowData.unidadSeleccionada}</label>;
    }
  };

  const calendarBody = (rowData) => {
    if (solicitud.estadoId === 1 && seleccionado === 1) {
      if (
        typeof rowData.fechaPrometida === "string" &&
        rowData.fechaPrometida !== ""
      ) {
        /*                 const [day, month, year] = rowData.fechaPrometida.split('/');
                                  const dateObject = new Date(`${year}-${month}-${day}`);
                                  dateObject.setDate(dateObject.getDate() + 1); */
        return (
          /*                     <Calendar
                                              dateFormat="dd/mm/yy"
                                              showIcon
                                              value={dateObject}
                                              onChange={(e) => handleBlur(e, rowData.id, 'fechaPrometida')}
                                          ></Calendar> */
          <InputText
            type="date"
            value={convertDateFormat(rowData.fechaPrometida)}
            onChange={(e) =>
              handleInputChangeProductos(
                e,
                rowData.id,
                "fechaPrometida",
                rowData
              )
            }
            onBlur={() => handleProductUpdate(rowData)}
            // onBlur={(e) => debouncedHandleBlur(e, rowData.id, "fechaPrometida")}
            disabled={isSaving}
            max="9999-12-31"
          />
        );
      } else {
        return (
          /*                     <Calendar
                                              dateFormat="dd/mm/yy"
                                              showIcon
                                              value={rowData.fechaPrometida}
                                              onChange={(e) => handleBlur(e, rowData.id, 'fechaPrometida')}
                                          ></Calendar> */
          <InputText
            type="date"
            value={rowData.fechaPrometida}
            onChange={(e) =>
              handleInputChangeProductos(
                e,
                rowData.id,
                "fechaPrometida",
                rowData
              )
            }
            onBlur={() => handleProductUpdate(rowData)}
            //onBlur={(e) => handleBlur(e, rowData.id, "fechaPrometida")}
            disabled={isSaving}
            max="9999-12-31"
          />
        );
      }
    } else {
      return <label>{rowData.fechaPrometida}</label>;
    }
  };
  const formatDateToDDMMYYYY = (dateString) => {
    if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      console.error("Invalid date format");
      return "";
    }

    const [year, month, day] = dateString.split("-");

    return `${day}/${month}/${year}`;
  };

  const handleInputChangeProductos = (e, id, field, productoACambiar) => {
    let { value } = e.target;
    setIsSaved(false);
    let updatedProducto = { ...productoACambiar, [field]: value };

    if (field === "fechaPrometida") {
      updatedProducto.fechaPrometida = formatDateToDDMMYYYY(value);
    } else if (field === "cantidadOrdenada" || field === "precio") {
      const cantidadOrdenada = parseFloat(updatedProducto.cantidadOrdenada);
      const precio = parseFloat(updatedProducto.precio);

      // Update total based on the product of cantidadOrdenada and precio
      updatedProducto.total =
        isNaN(cantidadOrdenada) || isNaN(precio)
          ? 0
          : cantidadOrdenada * precio;
    } else if (field === "unidadSeleccionada") {
      updatedProducto.unidadSeleccionada = value;
    }

    // Find the index of the product with the matching ID
    const index = solicitudProductos.findIndex(
      (producto) => producto.id === updatedProducto.id
    );

    if (index !== -1) {
      // If a matching product is found, replace it with updatedProducto
      const updatedProductos = [...solicitudProductos];
      updatedProductos[index] = updatedProducto;

      // Set the updated state
      setSolicitudProductos(updatedProductos);
    }
  };

  console.log(solicitudProductos);

  return (
    <div>
      {isSaving ? (
        <>
          <div>
            <ProgressSpinner style={{ marginRight: "0.5rem" }} />
          </div>
          <span className="mb-4">Guardando productos...</span>
        </>
      ) : (
        <DataTable
          value={solicitudProductos}
          responsiveLayout="scroll"
          dataKey="id"
          emptyMessage="Sin productos, favor agregar"
          className="datatable-responsive"
          rows={10}
          filters={filters}
          showGridlines
        >
          <Column
            field="codigoProducto"
            header="Codigo"
            headerStyle={customHeaderStyle}
          ></Column>
          <Column
            field="nombreProducto"
            header="Producto"
            body={(rowData) => inputBodyDescripcion(rowData)}
            headerStyle={customHeaderStyle}
          ></Column>
          <Column
            field="unidadSeleccionada"
            header="Unidad"
            body={(rowData) => dropDownUnidades(rowData)}
            headerStyle={customHeaderStyle}
          ></Column>
          <Column
            field="cantidadOrdenada"
            header="Cantidad Ordenada"
            body={(rowData) => inputBodyCantidad(rowData)}
            headerStyle={customHeaderStyle}
          ></Column>
          <Column
            field="cantidadRecibida"
            header="Cantidad Recibida"
            body={(rowData) => inputBodyCantidadRecibida(rowData)}
            headerStyle={customHeaderStyle}
          ></Column>
          <Column
            field="precio"
            header="Precio Unitario"
            body={(rowData) => inputBodyPrecio(rowData)}
            headerStyle={customHeaderStyle}
          ></Column>
          <Column
            field="total"
            header="Total"
            body={(rowData) => currencyFormatter(rowData.total)}
            headerStyle={customHeaderStyle}
          ></Column>
          <Column
            field="fechaPrometida"
            header="Fecha Prometida Proveedor"
            body={(rowData) => calendarBody(rowData)}
            headerStyle={customHeaderStyle}
          ></Column>
          <Column
            field=""
            header=""
            body={(rowData) => actionBodyTemplate(rowData)}
            headerStyle={customHeaderStyle}
          ></Column>
        </DataTable>
      )}
    </div>
  );
};
const TablaProductosIngreso = (props) => {
  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const endpoint =
    props.baseUrl +
    "getProductosUsuario?id_usuario=" +
    props.usuario_id +
    "&id_empresa=" +
    props.solicitud.empresaId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: new Headers(),
        }).then((res) => res.json());

        // Set the initial value using data from the fetched JSON object
        props.setProductos(data.productos);
        setFilteredData(data.productos);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const filters = {
    id: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  };

  const customHeaderStyle = { backgroundColor: "#e0dddc" };

  const submitForm = async (codigo) => {
    const endpoint =
      props.baseUrl +
      "agregarProducto?id_solicitud=" +
      props.solicitud.id +
      "&codigo=" +
      codigo;
    try {
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: props.headerText,
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to create the request");
      }

      processData(data.respuesta);
    } catch (error) {
      console.error("Error:", error.message);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const processData = (respuesta) => {
    const endpoint =
      props.baseUrl + "getSolicitudProducto?id_solicitud=" + props.solicitud.id;
    const fetchData = async () => {
      try {
        const data = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: new Headers(),
        }).then((res) => res.json());

        props.setSolicitudProductos(data.solicitudProductos);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (respuesta) {
      fetchData();
      props.hideDialogProducto(); // Close the modal after submitting the form
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <Button onClick={() => submitForm(rowData.codigo)} icon="pi pi-plus" />
    );
  };

  const applyGlobalFilter = (event) => {
    setGlobalFilter(event.target.value);
    setFilteredData(
      props.productos.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(event.target.value.toLowerCase())
        )
      )
    );
  };

  return (
    <div>
      <div className="formgrid grid">
        <div className="field col">
          <label style={{ fontWeight: "bold" }}>Buscar: </label>
          <InputText
            type="text"
            placeholder="Codigo o Descripción"
            onChange={applyGlobalFilter}
          ></InputText>
        </div>
        <div className="field col"></div>
      </div>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="4"
          />
        </div>
      ) : (
        <DataTable
          value={filteredData}
          responsiveLayout="scroll"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          paginator
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} productos"
          dataKey="id"
          emptyMessage="No se encuentran productos"
          className="datatable-responsive"
          rows={10}
          filters={filters}
          rowHover
          showGridlines
        >
          <Column
            field="cuentaContable"
            header="Cuenta Contable"
            headerStyle={customHeaderStyle}
          ></Column>
          <Column
            field="codigo"
            header="Codigo"
            headerStyle={customHeaderStyle}
          ></Column>
          <Column
            field="descripcion"
            header="Descripcion"
            headerStyle={customHeaderStyle}
          ></Column>
          <Column
            field=""
            header=""
            body={(rowData) => actionBodyTemplate(rowData)}
            headerStyle={customHeaderStyle}
          ></Column>
        </DataTable>
      )}
    </div>
  );
};

const TablaProveedores = (props) => {
  const [loading, setLoading] = useState(true);
  const [correoSeleccionado, setCorreoSeleccionado] = useState(null);
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [comentarioCorreo, setComentarioCorreo] = useState("");
  const endpoint = props.baseUrl + "getCotizaciones?id_solicitud=" + props.id;

  useEffect(() => {
    (async () => {
      console.log(endpoint);
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: new Headers(),
      }).then((res) => res.json());
      props.setSolicitudProveedores(data.cotizaciones);
      setLoading(false);
    })();
  }, []);

  const filters = {
    id: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  };

  const customHeaderStyle = { backgroundColor: "#e0dddc" };

  const submitForm = async (codigo) => {
    const endpoint = props.baseUrl + "borrarProveedor?id_cotizacion=" + codigo;
    try {
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: new Headers(),
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to create the request");
      }

      processData(data.respuesta);
    } catch (error) {
      console.error("Error:", error.message);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const processData = (respuesta) => {
    const endpoint = props.baseUrl + "getCotizaciones?id_solicitud=" + props.id;
    const fetchData = async () => {
      try {
        const data = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: new Headers(),
        }).then((res) => res.json());

        props.setSolicitudProveedores(data.cotizaciones);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    /*         if (respuesta) {
                    fetchData();
                } */
    if (respuesta.respuesta) {
      if (
        respuesta.mensaje !== "" &&
        typeof respuesta.mensaje !== "undefined"
      ) {
        alert(respuesta.mensaje);
      }
      fetchData();
    } else {
      fetchData();
      if (
        respuesta.mensaje !== "" &&
        typeof respuesta.mensaje !== "undefined"
      ) {
        alert(respuesta.mensaje);
      }
    }
  };

  const actionBodyTemplate = (rowData) => {
    if (props.solicitud.estadoId === 1) {
      return (
        <Button
          onClick={() => submitForm(rowData.id)}
          icon="pi pi-trash"
          severity="danger"
        />
      );
    }
  };

  // FUNCION DEBERÍA ESTAR ACA
  const handleFileUploadProveedor = (event, rowData) => {
    console.log(event);
    console.log(rowData);
    const file = event.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Data = btoa(e.target.result);

        // Determine content type based on file extension
        const contentType = file.type;
        const fileName = file.name;
        const fileSize = file.size;

        props.setEncodedFile(base64Data);

        subirArchivoProveedor(
          JSON.stringify({
            contentType: contentType,
            fileName: fileName,
            base64: base64Data,
            fileSize: fileSize,
            idSolicitud: parseInt(props.id, 10),
            idProveedor: rowData.codigo.toString(),
            idCotizacion: rowData.id,
          })
        );
      };

      reader.readAsBinaryString(file); // Fallback for other types of files
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const subirArchivoProveedor = async (archivo) => {
    const endpoint = props.baseUrl + "adjuntarArchivoCotizacion";
    setIsLoading(true);
    setLoading(true);
    try {
      const data = await fetch(endpoint, {
        method: "POST",
        credentials: "include",
        redirect: "follow",
        body: archivo,
        headers: { "Content-Type": "application/json" },
      }).then((res) => res.json());

      setIsLoading(false);

      if (data.mensaje !== "OK") {
        throw new Error("Failed to create the request");
      }

      if (data.respuesta) {
        window.location.reload();
      } else {
        alert("Hubo un problema al subir el archivo");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error.message);
      // Handle other errors (e.g., network error)
    }
    setLoading(false);
  };

  const cotizacionSubidaTemplate = (rowData) => {
    if (!rowData.archivos) {
      return (
        <>
          <FileUpload
            mode="basic"
            chooseLabel="Subir Archivo"
            uploadLabel="Subir Archivo"
            cancelLabel="Cancelar"
            multiple={false}
            customUpload
            uploadHandler={(e) => handleFileUploadProveedor(e, rowData)}
            disabled={isLoading}
          />
        </>
      );
    } else {
      return (
        <Button
          label="Ver Archivo"
          icon={"pi pi-file"}
          className="p p-button-success"
          onClick={() => bajarArchivo(rowData.id, rowData)}
        />
      );
    }
  };

  const enviarCorreoTemplate = (rowData) => {
    if (
      props.solicitud.estadoId === 1 &&
      rowData.correo !== "" &&
      !rowData.enviado
    ) {
      return (
        <Button
          label="ENVIAR"
          severity="success"
          onClick={() => enviarCorreo(rowData)}
        />
      );
    } else if (rowData.enviado) {
      return <label style={{ fontWeight: "bold" }}>Correo Enviado</label>;
    }
  };

  const correoTemplate = (rowData) => {
    if (props.solicitud.estadoId === 1 && !rowData.enviado) {
      return (
        <InputText
          type="text"
          placeholder="Correo Proveedor"
          onChange={(e) => handleInputChange(e, rowData.id, "correo")}
        ></InputText>
      );
    } else {
      return <label>{rowData.correo}</label>;
    }
  };
  const enviarCorreo = (rowData) => {
    if (props.solicitudProductos.length === 0) {
      alert("No tiene productos asociados a la solicitud");
    } else {
      if (validateEmail(rowData.correo)) {
        setComentarioCorreo("");
        setCorreoSeleccionado(rowData);
        setVisibleDialog(true);
      } else {
        alert("Ingrese un correo valido");
      }
    }
  };

  const handleDialogHide = () => {
    setVisibleDialog(false); // Close the dialog
  };

  const bajarArchivo = async (idCotizacion, rowData) => {
    setLoading(true);
    try {
      const archivosObject = JSON.parse(rowData.archivos);
      const nombreArchivo = archivosObject.nombre;

      const response = await fetch(
        props.baseUrl + "descargar/adjunto/cotizacion?id=" + idCotizacion,
        {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: { "Content-Type": "application/json" },
        }
      );
      if (!response.ok) {
        throw new Error(`Failed to download file. Status: ${response.status}`);
      }
      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      const fileName =
        response.headers.get("Content-Disposition")?.split("=")[1] ||
        nombreArchivo;

      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
    setLoading(false);
  };

  const enviarCorreoSubmit = async (idCotizacion, descripcion, correo) => {
    setVisibleDialog(false);
    props.setLoading(true);
    const endpoint =
      props.baseUrl +
      "enviarCorreoProveedor?id_solicitud=" +
      props.id +
      "&id_cotizacion=" +
      idCotizacion +
      "&descripcion=" +
      descripcion +
      "&correo=" +
      correo;
    try {
      const data = await fetch(endpoint, {
        method: "POST",
        credentials: "include",
        redirect: "follow",
        headers: props.headerText,
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to create the request");
      }

      processData(data.respuesta);
    } catch (error) {
      console.error("Error:", error.message);
      // Handle the error (e.g., show an error message to the user)
    }
    props.setLoading(false);
  };

  const handleInputChange = (e, id, field) => {
    const { value } = e.target;

    // Update the state with the new value
    const updatedLista = props.solicitudProveedores.map((proveedor) => {
      if (proveedor.id === id) {
        return { ...proveedor, [field]: value };
      }
      return proveedor;
    });

    // Set the updated state
    props.setSolicitudProveedores(updatedLista);
  };

  const actionSeleccionarProveedor = (rowData) => {
    let checkSeleccionado = 0;
    let idProveedorSelec;
    props.solicitudProveedores.forEach((obj, i) => {
      if (obj.seleccionado) {
        checkSeleccionado++;
        idProveedorSelec = obj.id;
      }
    });
    props.setSeleccionado(checkSeleccionado);

    if (!rowData.seleccionado && checkSeleccionado === 0) {
      return (
        <Button
          label="Seleccionar"
          severity="success"
          onClick={() => submitProveedor(rowData.id)}
        />
      );
    } else if (rowData.seleccionado && rowData.id === idProveedorSelec) {
      return <label style={{ fontWeight: "bold" }}>SELECCIONADO</label>;
    }
  };

  const submitProveedor = async (codigo) => {
    const endpoint =
      props.baseUrl + "seleccionarProveedor?id_cotizacion=" + codigo;
    try {
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: new Headers(),
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to create the request");
      }

      processData(data.respuesta);
    } catch (error) {
      console.error("Error:", error.message);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const validateEmail = (email) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const tableHeaderStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  };

  const tableCellStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  };

  console.log(props.solicitudProveedores);
  return (
    <div>
      <DataTable
        value={props.solicitudProveedores}
        responsiveLayout="scroll"
        dataKey="id"
        emptyMessage="Sin proveedores, favor agregar"
        className="datatable-responsive"
        rows={10}
        filters={filters}
        rowHover
        showGridlines
      >
        <Column
          field=""
          header="Acción"
          body={(rowData) => actionSeleccionarProveedor(rowData)}
          headerStyle={customHeaderStyle}
        ></Column>
        <Column
          field="codigo"
          header="Codigo"
          headerStyle={customHeaderStyle}
        ></Column>
        <Column
          field="nombreProveedor"
          header="Proveedor"
          headerStyle={customHeaderStyle}
        ></Column>
        <Column
          field="ciudad"
          header="Ciudad"
          headerStyle={customHeaderStyle}
        ></Column>
        <Column
          field="direccion"
          header="Direccion"
          headerStyle={customHeaderStyle}
        ></Column>
        <Column
          field="telefono"
          header="Teléfono"
          headerStyle={customHeaderStyle}
        ></Column>
        <Column
          field=""
          header="Correo"
          body={(rowData) => correoTemplate(rowData)}
          headerStyle={customHeaderStyle}
        ></Column>
        <Column
          field=""
          header="Cotizar"
          body={(rowData) => enviarCorreoTemplate(rowData)}
          headerStyle={customHeaderStyle}
        ></Column>
        <Column
          field=""
          header="Cotización"
          body={(rowData) => cotizacionSubidaTemplate(rowData)}
          headerStyle={customHeaderStyle}
        ></Column>
        <Column
          field=""
          header=""
          body={(rowData) => actionBodyTemplate(rowData)}
          headerStyle={customHeaderStyle}
        ></Column>
      </DataTable>

      <Dialog
        visible={visibleDialog}
        onHide={handleDialogHide}
        header="Enviar Correo (Vista Preliminar)"
        modal
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
      >
        {correoSeleccionado ? (
          <>
            <div className="card p-fluid">
              <div className="formgrid grid">
                <div className="field col">
                  {/*                             <label htmlFor="correo">Correo:</label>
                            <InputText id="correo" type="text" placeholder="Correo Proveedor" value={correoSeleccionado?.correo} onChange={(e) => handleInputChange(e, correoSeleccionado?.id, 'correo')} /> */}
                  <h2>¡Estimados, {correoSeleccionado.nombreProveedor}!</h2>
                  <p>
                    Desde {props.solicitud.empresa} te enviamos los siguientes
                    items solicitando una cotización para nuestra empresa.
                  </p>

                  {(() => {
                    if (typeof props.solicitudProductos !== "undefined") {
                      return (
                        <>
                          <table
                            style={{
                              width: "100%",
                              borderCollapse: "collapse",
                            }}
                          >
                            <thead>
                              <tr>
                                <th style={tableHeaderStyle}>#</th>
                                <th style={tableHeaderStyle}>Producto</th>
                                <th style={tableHeaderStyle}>Unidad</th>
                                <th style={tableHeaderStyle}>Cantidad</th>
                              </tr>
                            </thead>
                            <tbody>
                              {props.solicitudProductos.map(
                                (producto, index) => (
                                  <tr key={index}>
                                    <td style={tableCellStyle}>{index + 1}</td>
                                    <td style={tableCellStyle}>
                                      {producto.nombreProducto}
                                    </td>
                                    <td style={tableCellStyle}>
                                      {producto.unidadSeleccionada}
                                    </td>
                                    <td style={tableCellStyle}>
                                      {producto.cantidadOrdenada}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </>
                      );
                    }
                  })()}

                  <p>
                    Fecha deseable de entrega: {props.solicitud.fecha_entrega}
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    Numero Solicitud: #{props.solicitud.id}
                  </p>
                  <label style={{ fontWeight: "bold" }}>Descripcion:</label>
                  <InputTextarea
                    placeholder="Escriba sus comentarios ..."
                    autoResize
                    rows="3"
                    cols="30"
                    maxLength={150}
                    value={comentarioCorreo}
                    onChange={(e) => setComentarioCorreo(e.target.value)}
                  />
                  <p>
                    Agradecemos de antemano la confirmación de esta solicitud y
                    la posterior respuesta de la misma.
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    MAIL DESTINATARIO: {correoSeleccionado.correo}
                  </p>
                </div>
              </div>
              <div className="formgrid grid">
                <div className="field col">
                  <Button
                    label="Enviar Mail"
                    severity="success"
                    icon="pi pi-check"
                    onClick={() =>
                      enviarCorreoSubmit(
                        correoSeleccionado.id,
                        comentarioCorreo,
                        correoSeleccionado.correo
                      )
                    }
                    style={{ margin: "1%" }}
                  />
                </div>
                <div className="field col">
                  <Button
                    label="Cancelar"
                    severity="danger"
                    icon="pi pi-times"
                    onClick={handleDialogHide}
                    style={{ margin: "1%" }}
                  />
                </div>
              </div>
              {/* Add other form fields as needed */}
            </div>
          </>
        ) : (
          <p>Cargando ...</p>
        )}
      </Dialog>
    </div>
  );
};

const TablaProveedoresIngreso = (props) => {
  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const endpoint =
    props.baseUrl + "getProveedores?id_empresa=" + props.solicitud.empresaId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: new Headers(),
        }).then((res) => res.json());

        // Set the initial value using data from the fetched JSON object
        props.setProveedores(data.proveedores);
        setFilteredData(data.proveedores);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const submitForm = async (codigo) => {
    const endpoint =
      props.baseUrl +
      "agregarProveedor?id_solicitud=" +
      props.solicitud.id +
      "&codigo=" +
      codigo;
    try {
      console.log(endpoint);
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: new Headers(),
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to create the request");
      }

      processData(data.respuesta);
    } catch (error) {
      console.error("Error:", error.message);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const processData = (respuesta) => {
    const endpoint =
      props.baseUrl + "getCotizaciones?id_solicitud=" + props.solicitud.id;
    const fetchData = async () => {
      try {
        const data = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: new Headers(),
        }).then((res) => res.json());

        props.setSolicitudProveedores(data.cotizaciones);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (respuesta) {
      fetchData();
      props.hideDialogProveedor(); // Close the modal after submitting the form
    }
  };

  const filters = {
    id: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  };

  const customHeaderStyle = { backgroundColor: "#e0dddc" };

  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        onClick={() => submitForm(rowData.codigo)}
        disabled={!rowData.activo}
        icon="pi pi-plus"
      />
    );
  };

  const applyGlobalFilter = (event) => {
    setGlobalFilter(event.target.value);
    setFilteredData(
      props.proveedores.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(event.target.value.toLowerCase())
        )
      )
    );
  };

  console.log(filteredData);

  return (
    <div>
      <div className="formgrid grid">
        <div className="field col">
          <label style={{ fontWeight: "bold" }}>Buscar: </label>
          <InputText
            type="text"
            placeholder="Codigo o Nombre"
            onChange={applyGlobalFilter}
          ></InputText>
        </div>
        <div className="field col"></div>
      </div>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="4"
          />
        </div>
      ) : (
        <DataTable
          value={filteredData}
          responsiveLayout="scroll"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          paginator
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} proveedores"
          dataKey="id"
          emptyMessage="No se encuentran proveedores"
          className="datatable-responsive"
          rows={10}
          filters={filters}
          rowHover
          showGridlines
        >
          <Column
            field="codigo"
            header="Codigo"
            headerStyle={customHeaderStyle}
          ></Column>
          <Column
            field="nombreProveedor"
            header="Proveedor"
            headerStyle={customHeaderStyle}
          ></Column>
          <Column
            field="ciudad"
            header="Ciudad"
            headerStyle={customHeaderStyle}
          ></Column>
          <Column
            field="direccion"
            header="Dirección"
            headerStyle={customHeaderStyle}
          ></Column>
          <Column
            field="telefono"
            header="Teléfono"
            headerStyle={customHeaderStyle}
          ></Column>
          <Column
            field=""
            header=""
            body={(rowData) => actionBodyTemplate(rowData)}
            headerStyle={customHeaderStyle}
          ></Column>
        </DataTable>
      )}
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.colorMode === nextProps.colorMode &&
    prevProps.isNewThemeLoaded === nextProps.isNewThemeLoaded &&
    prevProps.onNewThemeChange === nextProps.onNewThemeChange
  );
};

export default React.memo(Solicitudes, comparisonFn);
